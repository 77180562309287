/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';
@use "mixins" as *;
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../node_modules/icheck-bootstrap/icheck-bootstrap.min.css";
@import "./admin-lte/adminlte.min.css";

@import "../node_modules/ngx-toastr/toastr";

@import "./core/light";
@import url("https://fonts.googleapis.com/css2?family=Figtree&display=swap");
@import url("font-icons/style.css");
@import '../node_modules/animate.css/animate.min';
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

/* -- triangle size -- */
$triangle-size: 7px;

body {
  background-color: $body-bg;
  color: $textcolor;
  font-family: "Figtree", sans-serif;
  height: 100vh;
  min-height: calc(100vh - 300px);
  overflow-x: hidden !important;
  padding-right: 0px;
  position: relative;
  width: 100%;
}

@include slt(575px) {
  .col-xs-12 {
    @include flex(100%);
  }
}

.form-check-input {
  appearance: auto;
}

.modal{
  top: 25%;
  transform: translate(0, -25%);
}

.headline_date .note div p {
  font-size: 1rem;
  font-family: "Mulish", sans-serif;
  font-weight: 900;
}

.headline h4 span.emoji {
  background-image: url(./assets/img/empower.svg);
  background-repeat: no-repeat;
  width: 25px;
  height: 20px;
  display: inline-block;
}

.tag-card {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.progress-bar {
  color: #fff;
  font-size: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 10px;
  margin: 0 1px;
}

.progress {
  background-color: transparent;
  height: 22px;
}

.dotted-border-btm {
  border-bottom: 1px dashed #c1bdbd;
}

.card-label-md {
  color: $gray-md-lgt-1;
  font-size: 1.438rem;
}

.progress-bar-list {
  line-height: 2.3rem;
}

.info-text {
  color: $black;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.calender-wrapper {
  .legend {
    flex-wrap: wrap;
    justify-content: start;
  }
}

.subtext-md {
  font-size: 1.5rem !important;
}

.card {
  select {
    background-color: #fff !important;
    color: #000 !important;
  }
}

.lbl-md.font-weight-bold {
  font-weight: 600 !important;
}

.swal2-container.swal2-center>.swal2-popup {
  border-radius: 15px !important;
}

.swal2-success-ring {
  border: 1px solid #3ce986 !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  background: #3CE986 !important;
}

.swal2-styled.swal2-confirm {
  border-radius: 30px !important;
  background-clip: padding-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline-offset: -1px;
  padding: 8px 40px;
  min-width: 100px;
  line-height: normal;
  background: $primary !important;
  border: 0px solid transparent !important;

  &:active {
    background: $primary !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.sucess-ico {
  img {
    width: 22px;
  }
}

.submitBtn {
  min-height: 45px;
  border: 0;
  min-width: 133px;
  background: #456790 !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
}

select {
  border: 1px solid #ececec !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  min-width: 220px;
  height: 35px;
  padding: 0 1rem !important;

  &:focus-visible {
    border: 1px solid #dfdfdf;
  }
}

.login-box,
.register-box {
  width: 100% !important;
  background: #e9ecef;
}

body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
  transition: margin-right 0.3s ease-in-out !important;
  margin-right: 0;
  margin-left: 0 !important;
}

.sidebar-mini.sidebar-collapse .content-wrapper,
.sidebar-mini.sidebar-collapse .main-footer,
.sidebar-mini.sidebar-collapse .main-header {
  margin-right: 4.6rem !important;
  margin-left: 0 !important;
}

body:not(.layout-fixed) .main-sidebar {
  top: 0 !important;
  right: 0 !important;
  position: fixed;
}

.container {
  max-width: 100% !important;
  padding: 0 15px !important;
  margin: 0;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  min-height: 38px;
  border: 1px solid #abbbcf;
  border-radius: 5px;
}

input::placeholder {
  font-size: 15px;
  color: #bcbcbc;
}

.credHeader {
  background: $header-bg;
  color: $header-text;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.formInner label {
  font-weight: normal !important;
  color: $textcolor;
}

.formInnerCol {
  margin-bottom: 15px;
}

.modal textarea {
  border: 1px solid #ececec;
  border-radius: 4px;
  min-height: 100px;
  resize: none;
}

.modal label {
  font-size: 1rem;
  color: #6e6e6e;
  font-weight: normal !important;
}

.modal input::placeholder,
.modal textarea::placeholder {
  font-size: 15px;
  color: #bcbcbc;
  font-weight: normal !important;
}

.modal-header {
  justify-content: center;
  align-items: center;

  h5 {
    font-weight: 700;
  }
}

.modal-header .close {
  font-size: 35px;
  font-weight: normal;
}

.modal .modal-footer {
  border: 0;
  justify-content: flex-start;
  padding: 0;
}

.modal .modal-footer button {
  background: $primary;
  border: 0px solid transparent;
  border-radius: 30px;

  &.cancel {
    color: $textcolor;
  }
}

.modal {
  .bottomBtnsModal {
    flex-direction: row-reverse;
  }

  .modal-footer {
    button.btn-primary {
      &:active {
        background: $primary !important;
      }
    }
  }
}

.modal .modal-footer button.cancel {
  background: #fff;
}

/* -- General Text / Notification Popup -- */
.notifiction-modal {
  .modal {
    display: flex!important;
    justify-content: space-around;
    align-items: center;
    .centered-all {
      width: 80%;
      @media screen and (min-width: 768px) {
        width: 60%
      }
    }
  }
  
}

/*Dashboard*/

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #456790;
  background-color: transparent;
  border-color: inherit;
  font-weight: 600;
  border-bottom: 4px solid #456790 !important;
  border: 0;
}

.nav-tabs {
  border-bottom: 0;
  width: 100%;
  font-size: 18px;
  overflow: auto;
  display: inline-block;
  white-space: nowrap;
  padding: 0 10px 2px;
}

.nav-tabs .nav-item:first-child {
  padding-bottom: 14px !important;
}

.nav-tabs a {
  color: #456790;
  cursor: pointer;
  padding: 0 0 16px 0;
}

.nav-tabs .nav-item {
  padding: 0 45px 0 0;
  display: inline-block;
}

.nav-tabs .nav-button {
  margin: 0 0 12px 0;
  border-bottom: 4px solid transparent !important;
}

.tabHeadMain {
  display: flex;
  width: 100%;
  background: #fff;
  min-height: 50px;
  align-items: center;
  margin-bottom: 34px;
  box-shadow: 0px 3px 12px #00000029;
  padding-left: 60px;
  padding-right: 60px;
}

.tabHeadMain .container {
  padding: 0;
}

.tabHead {
  font-size: 1.2rem;
  color: $black;
  font-weight: 600;
  padding: 0;
  padding: 0 6px;
  /*New added*/
}

.tabHead span {
  display: inline-block;
  width: auto;
  margin: 0 6px 0 0;
  font-size: 22px;
  cursor: pointer;
}

.overviewMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
}

.assmntTblHd {
  font-size: 20px;
  color: #456790;
  width: 100%;
  display: inline-block;
  background: #fff;
  padding: 11px 15px;
  margin-top: 15px;
}

.assmntTbl table th,
.teacherAssmntTbl table th {
  padding: 7px 15px;
  background: #e2e8ef;
  color: #456790;
}

.assmntTbl table td,
.teacherAssmntTbl table td {
  padding: 1rem 2rem;
  border-bottom: 1px solid #e2e8ef;
  background: #fff;
}

.assmntTbl table,
.teacherAssmntTbl table {
  width: 100%;
  box-shadow: 0px 1px 3px #0000001a;
}

.assmntTbl,
.teacherAssmntTbl {
  width: 100%;
  max-height: 195px;
  overflow: auto;
  display: inline-block;
}

.teacherAssmntTbl {
  max-height: 203px;
}

.assmntTblMain {
  display: inline-block;
  width: 100%;
  margin: 0 0 25px;
}

.boxMain {
  display: inline-block;
  width: 100%;
  background: #fff;
  border-radius: 3px;
}

.boxHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  min-height: 60px;
  border-bottom: 1px solid #ededed;

  &.flex-wrap {
    flex-wrap: wrap;

    p {
      color: #757575;
      font-size: 12px;
      margin-right: 20%;
    }
  }
}

.chartArea {
  padding: 15px;
}

.boxHead h5 {
  font-size: 20px;
  color: #456790;
}

.add-class {
  font-size: 16px;
  color: #fff;
  border: 0;
  background: #456790;
  border-radius: 4px;
  height: 35px;
  min-width: 96px;
}

.calendarMain {
  display: inline-block;
  width: 100%;
  margin: 0 0 25px;
}

.add-class i {
  font-size: 14px;
  margin: 0 4px 0 0;
}

/*Dashboard*/

/*Card Display*/

.cardDsplMain {
  display: inline-block;
  width: 100%;
  margin: 25px 0 0;
}

.cardDsplRow {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 15px;
  padding: 5px 10px;
}

.cardDsplLft {
  width: 358px;
  padding: 10px 5px;
}

.cardDsplLftInner {
  display: flex;
  width: 100%;
  background: #fff;
  height: 100%;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: $textcolor;
  padding: 10px;
  border-radius: 3px;
  border: 0 none;
}

.cardDsplRyt {
  width: calc(100% - 358px);
  padding: 5px;
}

.cardDsplRyt ul {
  height: 100%;
  margin: 0 -5px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.cardDsplRyt ul li {
  width: 20%;
  align-items: center;
  padding: 5px;
}

.cardDsplRyt ul li .cardDsplRytItems {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  background: #d4e7ff;
  min-height: 70px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 5px;
  font-size: 15px;
  color: #272727;
}

.cursor-pointer {
  cursor: pointer;
}

.cardDsplRyt ul li span {
  width: 100%;
  max-width: 150px;
}

.form-control[readonly] {
  background-color: #fff;
}

/*Card Display*/

/*Left Panal*/

.lftPanalMain {
  display: inline-block;
  width: 100%;
  background: #fff;
  padding-right: 0;
  box-shadow: 0px 3px 1px #00000029;
  height: calc(100vh - 200px);
  /*height: calc(100vh - 142px);*/
  overflow: auto;
  padding-left: 0;
}

.lftPanal {
  display: inline-block;
  width: 100%;
  background: #fff;
  /*box-shadow: 0px 3px 20px #00000029;*/
}

.lftPanal ul {
  list-style: none;
  padding: 0;
}

.lftPanal ul li.lftPnlheading {
  padding: 11px 15px;
  color: $black;
  background: #f4f3f9;
  font-size: 18px;
  font-weight: 600;
}

.lftPanal ul li {
  cursor: pointer;
}

.lftPanal ul li a {
  padding: 14px 15px 14px 15px;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  color: #272727;
  font-weight: 500;
  border-bottom: 1px solid #e3e3e3;

  &:hover {
    color: $white !important;
    background: $purple-linear;
  }
}

.lftPanal ul li a.active {
  background: $purple-linear;
  color: $white;
}

.rytPanal {
  padding: 30px;
  /*padding-right: 0;*/
  padding-bottom: 0;
}

.assessmentInfoMain .lftPnlheading {
  padding-left: 30px !important;
}

.assessmentInfoMain li a {
  padding-left: 30px !important;
}

.rytHeadMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}

.rytHeadMain h3 {
  font-size: 20px;
  color: $black;
  font-weight: 600;
  margin: 0;
}

.rytHeadMain button {
  min-width: 218px;
  min-height: 40px;
  font-size: 16px;
  padding: 0 10px;
  border-radius: 4px !important;
  background: #456790;
}

/*Left Panal*/

/*Default Table*/

.assmntTbl table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}






/*Custom Radio*/

.containerLabel .checkmark {
  border-radius: 100%;
}



/* The container */
.containerLabel {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #abbbcf;
}

/* On mouse-over, add a grey background color */
.containerLabel:hover input~.checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.containerLabel input:checked~.checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerLabel input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerLabel .checkmark:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $txt-purple;
}

/*Custom Radio*/

/*Sidebar*/

.nav-sidebar a.active {
  background: none !important;
  box-shadow: none !important;
}

.nav-sidebar a {
  color: #343232 !important;
  border-bottom: 1px solid #e9e9e9;
  border-radius: 0 !important;
  padding: 10px 8px;
  margin: 0 !important;
  width: 100% !important;
  font-size: 1rem;
  display: flex;
  font-weight: 500;
  justify-content: flex-start;
}

.nav-sidebar .nav-item .nav-icon {
  margin-left: 0.05rem;
  font-size: 1.2rem;
  margin-right: 0.2rem;
  text-align: center;
  width: 22px;
}

.closeMenuMain {
  width: 100%;
  padding: 10px 10px;
  text-align: right;
}

.closeMenuMain a {
  color: #999 !important;
  padding: 1rem;
  font-size: 1.3rem;
}

.sidebar {
  padding: 0;
}

.main-sidebar {
  background: #fff;
}

.nav-sidebar a p {
  padding: 0 0 0 6px;
}

.sidebar-mini.sidebar-collapse .main-sidebar,
.sidebar-mini.sidebar-collapse .main-sidebar::before {
  width: 0;
}

.sidebar-mini.sidebar-collapse .content-wrapper,
.sidebar-mini.sidebar-collapse .main-footer,
.sidebar-mini.sidebar-collapse .main-header {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover {
  width: 0 !important;
}

.nav-sidebar .nav-link .fa-circle {
  display: flex;
  align-items: center;
}
.nav-sidebar {
  img {
    width: 22px;
    height: 22px;
  }
}
/*Sidebar*/

/*Student Dashboard*/

.chartBtmArea {
  background: #fff;
  display: inline-block;
  width: 100%;
  padding: 15px;
}

.chartBtmCol {
  display: inline-block;
  width: auto;
  font-size: 15px;
  color: #000;
  margin: 0 50px 0 0;
}

.chartBtmCol span {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #666;
  margin: 0 2px 0 0;
}

.chartBtmCol:first-child span {
  background: #70ad47;
}

.chartBtmCol:nth-child(2) span {
  background: #ffc000;
}

.chartBtmCol:nth-child(3) span {
  background: #ff0000;
}

.chartBtmCol:nth-child(4) span {
  background: #e0e0e0;
}

.strengthMain {
  display: inline-block;
  width: 100%;
}

.strengthRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 6px 15px;
}

.strengthCol {
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: #000;
}

.strengthCol span {
  width: 40px;
  font-size: 15px;
  display: inline-block;
  margin: 0 8px 0 0;
}

.strengthCol span img {
  max-width: 100%;
}

.strengthCol .strengthDip {
  width: 35px;
  display: inline-block;
  background: #70ad47;
  height: 35px;
  border-radius: 100%;
  border: 8px solid #fff;
  box-shadow: 0px 3px 15px #00000029;
}

.strengthCol .strengthDip.yellow {
  background: #ffc000;
}

.strengthCol .strengthDip.red {
  background: #ff0000;
}

.strengthCol .strengthDip.grey {
  background: #e0e0e0;
}

/*Student Dashboard*/

/*Empty List View*/

.emptyAssmntMain {
  display: inline-block;
  width: 100%;
}

.emptyAssmntInner {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 60px 15px;
}

.emptyAssmntInner span {
  display: inline-block;
  width: 100%;
  margin: 0 0 18px;
}

.emptyAssmntInner h5 {
  font-size: 32px;
  color: #456790;
  font-weight: 500;
  margin: 0 0 10px;
  display: inline-block;
  width: 100%;
}

.emptyAssmntInner p {
  font-size: 20px;
  margin: 0 0 10px;
  color: #7d7d7d;
  display: inline-block;
  width: 100%;
}

/*Custom Checkbox*/
/* The container */
.container-check {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 !important;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #abbbcf;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container-check:hover input~.checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked~.checkmark {
  background-color: #456790;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Custom Checkbox*/

.padding0 {
  padding: 0;
}

.mobLabel {
  display: none;
}

canvas {
  width: 100%;
}

.cardDsplRytItems:hover {
  background: #bad9ff !important;
}

.cardDsplRytItems.selected {
  background: $blue-linear !important;
  color: #fff !important;
}

.cardDsplRytItems.deselected {
  background: #c4d0de !important;
  color: #272727 !important;
}

.credIcons {
  position: relative;
  display: inline-block;
  width: 100%;
}

.credIcons input {
  padding-right: 45px;
}

.credIcons button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: transparent;
  border: 0;
  font-size: 18px;
  color: $gray-lgt;
}



.addClassMain .modal-header {
  align-items: center;
}

.addClassMain select {
  width: 100%;
  height: 45px;
}

.userProfile span {
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.px-5 {
  padding: inherit !important;
}

.footerMain {
  padding: 5px 30px;
  display: inline-block;
  width: 100%;
  text-align: center;
  background: #7f95ae;
  bottom: 0;
  position: absolute;
}

.footerMain p {
  margin: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}

.loginFormMain .form-group {
  width: 100%;
  display: inline-block;
}

.reportMain .reportsTabs li.nav-item {
  padding-bottom: 0 !important;
}

.assessmentInfoMain .rytPanal {
  padding-right: 25px;
  padding-left: 25px;
}

.reportsFilterCol label {
  font-size: 16px !important;
}

.topCardCol span {
  font-size: 1rem !important;
}

.topCardCol label {
  font-size: 16px !important;
}

.topCardLbl h5 {
  font-size: 20px !important;
}

.groupRytBot h5 {
  font-size: 20px !important;
}

.groupRytTop p {
  font-size: 16px !important;
}

.studentBtmScroll label {
  font-size: 14px !important;
}

.studentTblInner .assmntTbl td {
  font-size: 15px !important;
}

.dashContAreaStudent .c-calendar .c-calendar-month .c-calendar-month-1 .c-calendar-month-header {
  font-size: 16px !important;
}

.dashContAreaStudent .c-calendar .c-calendar-middle {
  display: flex !important;
  align-items: center !important;
  padding: 3px 0 0 !important;
}

@media only screen and (max-width: 1500px) {

  .studentsTblMain .studentTblInner {
    height: 385px;
    overflow: auto;
  }

  .login-right button {
    font-size: 14px !important;
    margin-bottom: 15px !important;
  }

  .topCardCol label {
    font-size: 14px !important;
  }

  .topCardCol span {
    font-size: 15px !important;
  }

  .add-class {
    font-size: 14px;
    height: 32px;
    min-width: 80px;
  }

  .login-right button[type="reset"] {
    margin-bottom: 0px !important;
  }

  .strengthCol {
    font-size: 14px;
  }

  .add-class {
    font-size: 14px;
    height: 32px;
    min-width: 80px;
  }

  .nav-sidebar a {
    font-size: 16px;
  }

  .cardFormQst {
    font-size: 18px !important;
  }

  .cardFormAnsrCol label {
    font-size: 15px !important;
    width: max-content;
  }

  .cardFormAnswer .containerLabel .checkmark:after {
    top: 4px !important;
    left: 4px !important;
    width: 10px !important;
    height: 10px !important;
  }

  .cardFormAnswer .checkmark {
    height: 20px !important;
    width: 20px !important;
  }

  .assStudentMain .nxtPrevMain button {
    min-height: 40px !important;
    font-size: 14px !important;
  }

  .studentsTblMain .studentTblInner {
    height: 385px;
    overflow: auto;
  }

  .login-right button {
    font-size: 14px !important;
    margin-bottom: 15px !important;
  }

  .login-right button[type="reset"] {
    margin-bottom: 0px !important;
  }

  .strengthCol {
    font-size: 14px;
  }

  .add-class {
    font-size: 14px;
    height: 32px;
    min-width: 80px;
  }

  .nav-sidebar a {
    font-size: 16px;
  }

  .cardFormQst {
    font-size: 18px !important;
  }

  .cardFormAnsrCol label {
    font-size: 15px !important;
    width: max-content;
  }

  .cardFormAnswer .containerLabel .checkmark:after {
    top: 4px !important;
    left: 4px !important;
    width: 10px !important;
    height: 10px !important;
  }

  .cardFormAnswer .checkmark {
    height: 20px !important;
    width: 20px !important;
  }

  .assStudentMain .nxtPrevMain button {
    min-height: 40px !important;
    font-size: 14px !important;
  }

  .cardDsplRyt ul li .cardDsplRytItems {
    font-size: 14px;
  }

  .cardDsplLftInner {
    font-size: 18px;
  }

  .topCardCol label {
    font-size: 14px !important;
  }

  .topCardCol span {
    font-size: 15px !important;
  }

  .topCardLbl h5 {
    font-size: 18px !important;
  }

  .topCardCol {
    label {
      font-size: 1rem !important;
      color: #6e6e6e !important;
    }
  }

  .tabHead {
    font-size: 20px;
  }

  .tabHead span {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .cardDsplRyt {
    display: block !important;
    height: inherit !important;
  }
}

@media only screen and (max-width: 767px) {
  .headline h4 span.emoji {
    width: 23px;
    height: 20px;
    position: absolute;
    left: -20px;
  }
  .reportsFilterCol select {
    max-width: 100% !important;
  }

  .mobLabel {
    width: 100%;
    color: #666;
    font-weight: normal;
  }

  .tabHeadMain {
    padding: 0 5px !important;
  }

  .cardDsplRyt {
    width: 100%;
    padding: 0;
  }

  .cardDsplRyt ul {
    display: inline-block;
    width: 100%;
    margin: 0;
  }

  .cardDsplRyt ul li {
    width: 100%;
  }

  .cardDsplRyt ul li .cardDsplRytItems {
    font-size: 16px;
    min-height: auto;
  }

  .cardDsplLftInner {
    display: inline-block;
    min-height: auto;
    font-size: 20px;
    padding: 5px 10px;
  }

  .cardDsplRyt ul li span {
    max-width: 100%;
  }

  .cardDsplLft.collapsed .cardDsplLftInner {
    background: #fff;
    color: #456790;
    border: 1px solid #456790;
  }

  .rytHeadMain {
    display: inline-block;
    width: 100%;
  }

  .lftPanalMain {
    height: auto !important;
  }

  .login-left {
    display: none !important;
  }

  .loginFormMain {
    margin: 20px 0 0 !important;
  }

  .login-inner .login-box {
    margin-top: 0 !important;
  }

  .login-main {
    padding-bottom: 0 !important;
  }

  #login-form {
    padding-bottom: 10px !important;
  }

  .login-box .btn-primary {
    margin-bottom: 20px !important;
  }

  .rytPanal {
    padding: 15px;
  }

  .cardDsplLftInner {
    background: #fff;
    color: #456790;
  }

  .cardDsplLft[aria-expanded="true"] .cardDsplLftInner {
    background: #7f95ae;
    color: #fff;
  }

  .cardDsplLft {
    width: 100%;
  }

  .container {
    padding: 0 15px !important;
  }

  .userProfile span {
    max-width: 60px;
  }

  .bs-rating-star {
    font-size: 40px !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .px-sm-2-c {
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 480px) {
  .userProfile span {
    display: none;
  }

  .nameCircle {
    margin: 0 !important;
  }
}

.modal-dialog {
  max-width: 850px;
}

.bs-datepicker-head {
  background: transparent linear-gradient(180deg, #4d3886 0%, #7d5fce 100%) 0% 0% no-repeat padding-box !important;
}

.bs-datepicker-body {
  table {
    td {
      color: #0c1219 !important;
      span {
        &.selected {
          // background-color: none !important;
          background: transparent linear-gradient(180deg, #4d3886 0%, #7d5fce 100%) 0% 0% no-repeat padding-box !important;
        }
        &.disabled {
          color: #beb0d4 !important;
        }
      }
    }
  }
} 
.bs-datepicker-body table td


td.week span {
  color: #7900ff !important;
}

.danger-class {
  border-color: red !important;
}

.invalid-feedback {
  display: block;
}

.invalid-box {
  border: 1px solid red;
  background: #f9e5e5 !important;
}

.my-custom-class .tooltip-inner {
  padding: 0.7rem;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #456790;
  color: #ffffff;

  ul {
    margin: 0;
    padding: 0 0.3rem;
  }
}

.bs-tooltip-auto .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #456790;
}

.bs-tooltip-right .arrow::before {
  border-right-color: #456790;
}

.bs-tooltip-top .arrow::before {
  border-top-color: #456790;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #456790;
}

.tooltip.show {
  opacity: 1;
}

/**New CSS Start
*************************************/
.wrapper {
  .main-header {
    padding: 0px 60px;
    border-bottom: 0 none;

    .container {
      padding: 0px !important;
    }

    @media only screen and (max-width: 768px) {
      padding: 0px 15px;
    }
  }

  .text-primary {
    color: var(--primary);
  }

  .container {
    padding: 0 !important;

    h4 {
      font-size: 23px;
      color: #595959;
      font-weight: 600;
    }
  }

  .main-container {
    padding: 0px 60px 22px;

    @media only screen and (max-width: 768px) {
      padding: 20px 15px 62px;
    }

    .btn {
      border-radius: 30px;
      background-clip: padding-box;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      outline-offset: -1px;
      padding: 8px 40px;
      min-width: 100px;
      line-height: normal;

      &.btn-md {
        min-width: 86px;
        min-height: 34px;
      }

      &.btn-small {
        font-size: 16px;
        padding: 5px 16px;
        line-height: 18px;
        height: fit-content;
        border: 0px solid transparent;
        min-width: 60px;

        @media only screen and (max-width: 768px) {
          min-width: 75px;
          padding: 5px 15px;
        }
      }

      &.btin-icon {
        padding: 5px 5px 5px 24px;

        @media only screen and (max-width: 768px) {
          padding: 5px 5px 5px 10px;
          min-width: 85px;
        }
      }

      &.btn-primary {
        background: $primary;
        border: 0px solid transparent;
      }

      &.btn-secondary {
        background: $secondary;
      }

      &.btn-outline-primary {
        background: $white;
        border-color: var(--primary);
      }

      &.btn-outline-danger {
        background: $white;
        color: $outline-danger-text;
        border: 1px solid $outline-danger;
      }

      &.determined-btn {
        background: $determined;
        color: $white;
        border: 0px solid transparent;
        font-size: 22px;
        font-weight: normal;
        padding: 10px 15px 10px;
        border-radius: 16px;

        &.focus,
        :focus,
        :hover {
          background: $determined;
          opacity: 0.8;
        }
      }

      &.btn-outline-purple {
        background: $white;
        color: $txt-purple;
        border: 1px solid $border-purple;
      }

      .play-icon {
        width: 18px;
        height: 18px;
        margin-left: 10px;
      }
    }

    .container {
      padding: 0 !important;
    }

    .nav-tabs {
      border-bottom: 1px solid $white;
      font-size: 16px;
      padding: 0px;
      overflow-y: hidden;

      @media only screen and (max-width: 768px) {
        // border: 1px solid $nav-tab-active;
        // border-radius: 16px;
        // background: $white;
        // margin-top: 15px;
        // justify-content: space-evenly;
        // display: flex;
        // overflow-y: hidden;
      }

      .nav-item {
        padding: 0;
        margin-bottom: 0;

        @media only screen and (max-width: 768px) {
          // min-width: 20%;
          // padding-left: 1.3rem;
          // padding-right: 1.3rem;
          // text-align: center;
        }

        &:first-child {
          padding-bottom: 0 !important;
        }

        .nav-link {
          color: $nav-tab-color;
          border-bottom: 5px solid transparent;
          padding: 8px 18px;
          font-weight: normal;
          border: 0px solid transparent;

          @media only screen and (max-width: 768px) {
            border-bottom: 0px solid $nav-tab-active;
            padding: 8px;
          }

          &:hover {
            border: 0px solid transparent;
          }

          &.active {
            color: $nav-tab-active;
            border-bottom: 5px solid $nav-tab-active !important;

            @media only screen and (max-width: 768px) {
              // color: $white;
              // border-bottom: 0px solid $nav-tab-active;
              // background: $nav-tab-active;
              // border-radius: 16px;
            }
          }
        }
      }
    }

    //Dashboard Challenge
    .top-skils {
      @media only screen and (max-width: 768px) {
        text-align: center;
      }

      .container-title {
        font-size: 22px;
        color: $textcolor;
        font-weight: 500;

        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      .top-skils-list {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        @media only screen and (max-width: 768px) {
          display: block;
        }


        .list-group {
          padding: 0rem 1rem;
          margin-bottom: 1rem;
          width: 100%;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          @media only screen and (max-width: 768px) {
            padding: 0rem 0.3rem;
          }

          a.list-group-item {
            display: flex;
            align-items: center;
            border: 0px;
            border-radius: 16px;
            padding: 15px;
            text-align: center;
            flex-direction: column;
            flex-flow: nowrap;
            justify-content: center;
            background: $box-white;

            @media only screen and (max-width: 768px) {
              flex-flow: wrap;
              display: block;
            }

            h6 {
              font-size: 22px;
              color: $textcolor;
              font-weight: 400;

              @media only screen and (max-width: 768px) {
                font-size: 16px;
                height: 40px;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
              }
            }

            img {
              margin-right: 8px;
              width: 55px;
              height: 55px;

              @media only screen and (max-width: 768px) {
                margin-right: 0px;
              }
            }

            .content-between {
              @media only screen and (max-width: 768px) {
                flex-flow: wrap;
              }
            }
          }
        }
      }
    }

    .card {
      background: $box-white;
      border-radius: 16px;
      padding: 38px 44px;
      box-shadow: 0 0 0;
      border: 0;
    }

    .challenge-card {
      margin-bottom: 25px;

      @media only screen and (max-width: 768px) {
        margin-top: 25px;
        margin-bottom: 0px;
      }

      .card {
        padding: 0;

        .card-header {
          font-size: 22px;

          .right-btn {
            float: right;
          }
        }

        .card-body {
          border: 0px;
          padding: 0px;

          .list-group {
            .list-group-item {
              border: 0px solid transparent;
              border-bottom: 1px solid #e5e4e4;
              border-radius: 0;
              margin-bottom: 0;
              font-size: 18px;
              padding: 0.8158rem 1.25rem;

              &:last-child {
                border-bottom: 0px solid #e5e4e4;
              }

              .track-btn {
                display: flex;
                align-items: center;
              }

              @media only screen and (max-width: 576px) {
                overflow-wrap: anywhere;
              }

              @media only screen and (max-width: 768px) {
                // font-size: 1rem;
                padding: 0.5rem;
              }
            }
          }

          .item-header {
            .list-group-item {
              &:first-child {
                border-radius: 15px 15px 0px 0px;
              }
            }
          }

          .track-list-name {
            font-size: 20px;
          }
        }
      }

      &.challenge-card-accordion {

        //margin-bottom: 0;
        .card {
          margin-bottom: 0;

          &.card-accordion {
            .card-header {
              border: 0px solid transparent;

              .plus-icon {
                display: none;

                @media only screen and (max-width: 768px) {
                  border: 1px solid #e6e6e6;
                  background: $white;
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  display: inline-block;
                  position: relative;
                  font-style: normal;
                  text-align: center;
                  line-height: 26px;
                  font-weight: 600;
                  font-size: 13px;
                  cursor: pointer;
                  margin-right: 5px;
                  top: -2px;
                }
              }
            }

            .collapse:not(.show) {
              display: block;

              @media only screen and (max-width: 768px) {
                display: none;
              }
            }

            .card-body {
              .list-group {
                .list-group-item {
                  &:first-of-type {
                    border-top: 1px solid #e5e4e4;
                  }

                  &:last-of-type {
                    border-radius: 0px 0px 16px 16px;
                  }
                }
              }
            }
          }
        }
      }
    }

    //Dashboard Challenge
  }

  .card-column {
    &.card {
      padding: 38px 29px;
    }

    .cols-column {
      column-rule: 1px double #ebe8e8;
      margin: 0px;
      list-style: none;

      &:first-of-type {
        border-right: 1px solid #ebe8e8;
        padding-right: 25px;
      }

      &:last-of-type {
        padding-left: 25px;
      }

      @media only screen and (max-width: 768px) {
        &:first-of-type {
          border-right: 0px solid #ebe8e8;
          padding-left: 0;
          padding-right: 0;
        }

        &:last-of-type {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .improvements {
      display: flex;
      align-items: flex-start;
      border: 0px;
      border-radius: 16px;
      padding: 15px 15px;
      flex-direction: column;
      flex-flow: nowrap;
      justify-content: flex-start;
      background: $box-white;
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;

      .img {
        width: 60px;
        height: 60px;
        padding: 10px;
        border-radius: 16px;
        margin-right: 15px;
        background: $improvements-start;
        color: $white;
      }

      .content {
        width: 100%;

        .name {
          font-size: 22px;
          font-weight: 500;
          line-height: normal;
        }

        .status {
          position: relative;

          &::before {
            display: block;
            width: 100%;
            height: 1px;
            border: 1px dashed #8d8d8d;
            content: "";
            position: absolute;
            top: 50%;
            opacity: 0.2;
          }

          .progress-status {
            font-size: 16px;
            color: $textcolor;
            background: $white;
            z-index: 0;
            align-items: center;
            padding: 0 10px 0 0px;
            font-weight: normal;
            line-height: normal;
          }

          .retings-count {
            font-size: 24px;
            font-weight: 500;
            color: $textcolor;
            background: $white;
            padding: 0 21px;
            z-index: 0;
            line-height: normal;

            .count {
              color: $improvements-start-status;
            }
          }
        }
      }

      &.start-box {
        .img {
          background: $improvements-start;
          color: $white;
        }

        .content {
          .status {
            .progress-status {
              color: $improvements-start-status;
              background: $white;
            }

            .retings-count {
              .count {
                color: $improvements-start-count;
              }
            }
          }
        }
      }

      &.mid-box {
        .img {
          background: $improvements-mid;
          color: $white;
        }

        .content {
          .status {
            .progress-status {
              color: $improvements-mid-status;
              background: $white;
            }

            .retings-count {
              .count {
                color: $improvements-mid-count;
              }
            }
          }
        }
      }

      &.end-box {
        .img {
          background: $improvements-end;
          color: $white;
        }

        .content {
          .status {
            .progress-status {
              color: $improvements-end-status;
              background: $white;
            }

            .retings-count {
              .count {
                color: $improvements-end-count;
              }
            }
          }
        }
      }
    }
  }

  .innertabHeadMain {
    @media only screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      min-height: 80px;
      position: fixed;
      top: 0;
      z-index: 10000;

      background: $header-bg;
      color: $header-text;

      .tabHead {
        color: $header-text;
      }
    }
  }

  // Build Positive Brain
  .positive-brain-group {
    padding: 0px 0px 100px;

    @media only screen and (max-width: 768px) {

      padding: 22px 0px 60px;
    }

    .list-group {
      max-width: 340px;
      margin: 1rem auto;
      gap: 0.785rem;
      display: flex;
      flex-direction: column;
      padding-left: 0;

      .list-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .trash-btn {
          display: block;
          background: $white;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          align-items: center;
          border: 1px solid #d9d9d9;
          margin-left: 1rem;
          min-width: auto;
          padding: 0;
          position: relative;
          top: 2px;

          i {
            color: $primary;
            line-height: 36px;
            text-align: center;
          }
        }
      }

      .list-group-item {
        border-radius: 50px;
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        padding: 0.6rem 1.25rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .form-check-input:checked+.form-checked-content {
      opacity: 0.5;
    }

    .form-check-input-placeholder {
      border-style: dashed;
    }

    [contenteditable]:focus {
      outline: 0;
    }

    .list-group-checkable .list-group-item {
      cursor: pointer;
    }

    .list-group-item-check {
      position: absolute;
      clip: rect(0, 0, 0, 0);
    }

    .list-group-item-check:hover+.list-group-item {
      color: #595959;
    }

    .list-group-item-check:checked+.list-group-item {
      color: $white;
      background-color: $jk-bluegradient;
      padding-right: 3rem;

      &::before {
        display: inline-block;
        position: absolute;
        right: 10px;
        border-radius: 100%;
        padding: 5px;
        background: #fff;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        content: "\f00c";
        color: var(--jk-blue);
      }
    }

    .list-group-item-check[disabled]+.list-group-item,
    .list-group-item-check:disabled+.list-group-item {
      pointer-events: none;
      filter: none;
      opacity: 0.5;
    }

    .list-group-radio .list-group-item {
      cursor: pointer;
      border-radius: 0.5rem;
    }

    .list-group-radio .form-check-input {
      z-index: 2;
      margin-top: -0.5em;
    }

    .list-group-radio .list-group-item:hover,
    .list-group-radio .list-group-item:focus {
      background-color: $jk-bluegradient;
      color: $white;
    }

    .list-group-radio .form-check-input:checked+.list-group-item {
      background-color: var(--jk-body);
      border-color: var(--jk-blue);
      box-shadow: 0 0 0 2px var(--jk-blue);
    }

    .list-group-radio .form-check-input[disabled]+.list-group-item,
    .list-group-radio .form-check-input:disabled+.list-group-item {
      pointer-events: none;
      filter: none;
      opacity: 0.5;
    }
  }

  .footer-inner-action {
    width: 100%;
    padding: 1rem 1rem;
    text-align: center;
    background: $body-bg;
    margin: 0rem auto;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;

    @media only screen and (max-width: 768px) {
      background: $white;
      z-index: 10;
    }
  }
}

.txtarea {
  min-height: 160px;
  color: #595959;
  resize: none;
  border: 0 none;
  border-radius: 10px;
  padding: 1rem 1.5rem;
}

.card-white {
  min-height: 278px;
  background: #ffffff !important;
  padding: 0 !important;
  color: $black;

  .card-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
  }

  h5 {
    font-weight: 600;
    text-align: center;
  }
}

.bs-rating-star {
  font-size: 53px;

  &.active {
    background: $blue-3;
    background: $blue-linear;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #fff;
    margin: 3px;
  }
}

.dotted-line {
  border-top: 2px dotted $gray-500;
  position: relative;
}

.right-arrow {
  font-size: 18px;
  position: absolute;
  top: -10px;
  color: $green-1;
  right: -7px;
}

.dot-sm {
  width: 10px;
  height: 10px;
  background: $txt-org;
  border-radius: 10px;
  position: relative;
  top: 6px;
  z-index: 1;
}

.dot-sm.tab-dot {
  top: 0;
}

.rating-label {
  color: $textcolor;
  font-size: 14px;
  position: relative;
  margin-top: 0.8rem;

  .lft {
    position: absolute;
    left: -6px;
  }

  .rgt {
    position: absolute;
    right: -14px;
  }
}

.rating-label-3 {
  display: flex;
  justify-content: space-evenly;
  text-align: center;

  div {
    width: 33.3%;
  }

  .lft {
    left: -30px;
  }

  .rgt {
    right: -32px;
  }
}

.modal-confirm {
  .modal-header {
    border-bottom: 1px dashed $gray-500;
    padding: 0 0 1rem 1rem;
    justify-content: center;

    modal-header h4 {
      color: $black;
      font-size: 22px;
      font-weight: 600;
    }
  }

  &.modal-body {
    padding: 1rem 0;
  }
}

.modal-content {
  border-radius: 15px;
}

.modal {
  .txtarea-bordered {
    border: 1px solid $gray-300;
    min-height: 100px;
    border-radius: 10px !important;
  }

  .btn {
    padding: 8px;
    min-width: 120px;
  }

  .btn-primary {
    background: $primary;
    border: 0px solid transparent;
    border-radius: 30px;

    &:active {
      background: $primary !important;
    }
  }

  .btn-secondary {
    background: $secondary;
    color: $gray-txt;
    font-size: 18px;
  }

  .btn-danger {
    background: transparent linear-gradient(102deg, #a80404ec 0%, #e40c0ca3 100%) 0% 0%;
    border: 0px solid transparent;
    border-radius: 30px;
  }

  .modal-title {
    font-weight: 700;
  }
}

.card-rounded {
  &.card {
    padding: 0.5rem !important;
    color: $textcolor;
    width: 100%;
    height: 100%;

    .card-body {
      padding-top: 0;
    }
  }

  .card-header {
    font-size: 22px;
    color: $black;
    border-bottom: 0 none;
    font-weight: 600;
  }

  ul {
    margin: 0;
    padding: 0;

    &.list-bordered-btm {
      li {
        border-bottom: 1px solid #d5d1d1;
        list-style-type: none;
        padding: 1rem 0;

        &:first-child {
          padding-top: 0;
        }
      }

      li.no-border {
        border: 0 none !important;
      }
    }
  }
}

.font-20 {
  font-size: 1.2rem !important;
}

.px-lg {
  padding-left: 4rem;
  padding-right: 4rem;
}

.custom-cal {
  width: 90%;

  .btn-group {
    .btn-link {
      color: $gray-md;
      padding: 0 1rem;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  .cal-head {
    h3 {
      color: $gray-md;
      font-size: 1rem;
    }
  }

  .cal-header {
    background: $gray-100;
  }

  .cal-cell-row,
  .cal-month-view .cal-day-cell:not(:last-child),
  .cal-days {
    border: 0 !important;
  }

  .cal-day-number {
    color: $black !important;
    font-size: 14px !important;
    font-weight: 600;
    opacity: 1;
    margin-right: 0;
    margin-top: 6px;
    float: none;
  }

  .cal-day-cell,
  .cal-cell-top {
    min-height: 30px;
    padding-top: 1px;
    text-align: center;
  }

  mwl-calendar-month-cell {
    flex-basis: 30px;
    max-width: 30px;
    border-radius: 50%;
    height: 30px;
  }

  .cal-cell-row {
    justify-content: space-around;
  }

  .cal-days {
    padding-top: 0.5rem;
  }

  .cal-cell-row {
    &:hover {
      background-color: transparent;
    }
  }

  .cal-month-view .cal-day-cell.cal-today {
    background: $blue-3;

    .cal-day-number {
      color: $white !important;
    }
  }

  .cal-cell-row {
    padding: 0.5rem 0;
  }
}

.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem !important;

  .circle-sm {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    position: relative;
    top: 5px;
    display: flex;
    margin-right: 0.3rem;
  }

  li {
    list-style-type: none;
    display: flex;
    padding-right: 1rem;
    font-size: 14px;
    color: #757575;
  }
}

.bad {
  background-color: $orange !important;
}

.ok {
  background-color: $purple-1 !important;
}

.good {
  background-color: $green-1 !important;
}

.no-entry {
  background-color: $gray-md-lgt !important;
}

.circle-md {
  min-width: 45px;
  min-height: 45px;
  border-radius: 50px;

  &.circle-border {
    border: 1px solid #d5d3d3;
    margin: 0.3rem;
    display: flex;
    justify-content: center;
  }

  img {
    width: 80%;
  }
}

.donut-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .chart-legend {
    display: unset;

    .legend-labels {
      display: flex;
      max-height: auto !important;
      background: transparent;
      justify-content: center;
    }

    .legend-label-color {
      border-radius: 50px;
    }

    .legend-label-text {
      color: $gray-md;
      font-size: 14px;
    }
  }

  .pie-label {
    color: $gray-md;
    font-size: 17px;
  }

  .title-center {
    position: absolute;
    width: 6rem;
    text-align: center;
    top: 7.3rem;
    font-size: 0.7rem;
  }
}

.custom-radios {
  div {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;

    +label {
      color: #333 !important;
      font-family: Arial, sans-serif;
      font-size: 14px;

      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid #d9d9d9;
        box-shadow: none;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        line-height: 44px;

        i {
          opacity: 0;
          transition: all 0.3s ease;
          color: #456790;
          position: relative;
          top: -6px;
        }
      }
    }

    &#color-1+label span {
      background-color: $white;
    }

    &#color-2+label span {
      background-color: $white;
    }

    &#color-3+label span {
      background-color: $white;
    }

    &#color-4+label span {
      background-color: $white;
    }

    &:checked+label span i {
      opacity: 1;
    }
  }
}

ul.list-btm-dotted {
  padding: 0;
  overflow-wrap: anywhere;

  li {
    border-bottom: 1px dashed #ccc;
    list-style-type: none;
    font-size: 17px;
    color: $black;
    padding: 1rem 0;
  }
}

.modal-center {
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: $black;
    padding: 0 0 0.5rem;
    font-weight: 600;
  }
}

.modal-sm {
  max-width: 480px;
}

.btn-row {
  .btn-primary {

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent;
  }

  .btn-outline-primary {
    min-width: 75px;
    min-height: 30px;
    background: $white;
    border: 2px solid #99b6d9;
    border-radius: 5px;
    color: #456790;
    padding: 2px 5px;
    font-size: 14px;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: $white;
    color: #456790;
  }
}

.btn-action {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-primary,
  .modal .btn-primary {
    background: $primary-btn;
    border-radius: 10px;

    &:active {
      background: transparent;
    }
  }
}

.credIcons {
  .btn-row {
    button {
      top: 0;
    }
  }
}

.circle-ico {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.border-top {
  border-top: 1px solid #e1e1e1;
}

.sm-center-txt {
  color: $gray-md-lgt-1;
}

.info-btn {
  color: $gray-lgt;
}

.c-form {
  input {
    border-radius: 5px;
    border: 0 none;
  }

  .form-check-label {
    font-size: 14px;
    color: $textcolor;
    padding-left: 1rem;
    padding-top: 0.3rem;
  }

  .form-check-input {
    width: 22px;
    height: 22px;
    background: $blue-3;
    border: 1px solid $blue-3;
  }

  h4 {
    font-size: 23px;
    color: #595959;
    font-weight: 600;
  }
}

.heading-md {
  font-size: 20px;
  color: $black;
  font-weight: 600;
}

.rotate-90 {
  transform: rotate(90deg);
}

.m-wd-sm {
  min-width: 50px;
}

.dotted-line-v {
  border-left: 2px dotted $gray-500;
  position: relative;
}

.down-arrow {
  font-size: 18px;
  position: absolute;
  color: $txt-org;
  bottom: -5px;
  left: -9px;
  z-index: 1;
}

.dot-sm-v {
  width: 10px;
  height: 10px;
  background: $green-1;
  border-radius: 10px;
  position: relative;
  top: 0;
  left: -13px;
  z-index: 1;
}

.card-white-sm {
  min-height: 100px;
  &.card-no-space {
    padding: 38px 14px !important;
  }
}

.circle-btn-bordered {
  display: block;
  background: $white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  align-items: center;
  border: 1px solid #d9d9d9;
  margin-left: 1rem;
  min-width: auto;
  padding: 0;
  text-align: center;

  &.circle-btn-lg {
    width: 50px !important;
    height: 50px !important;
    border: 1px solid #5C82FF !important;
  }

  i {
    color: $primary;
    line-height: 36px;
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
  }
}

.lbl-md {
  color: $gray-md-lgt-1;
  font-size: 18px;
  font-weight: normal !important;
  margin-bottom: 0;
}

.modal {
  .lbl-md {
    font-size: 22px;
    font-weight: 700 !important;
  }
}

.info-modal-btn {
  background: transparent;
  border: 0 none;
}

.txt-green {
  color: $txt-green;
}

.font-lg {
  font-size: 26px;
}
.font-sm {
  font-size: 1rem;
  color: $textcolor;
}
.border-right {
  border-right: 1px solid #d5d1d1;
}

.font-md {
  font-size: 18px;
  font-weight: 600;
}

.lbl-gray {
  font-size: 14px;
  color: $gray-md;
  font-weight: 600;
}

.lbl-gray-md {
  font-size: 20px;
  color: $textcolor;
  font-weight: 600;
}

.list-bordered-btm-dotted {
  padding: 0;

  li {
    border-bottom: 1px dashed #d5d1d1 !important;
    list-style-type: none;
  }

  .legend {
    margin-top: 0 !important;

    li {
      border-bottom: 0 !important;
    }
  }
}

.heading-1 {
  h4 {
    color: $black !important;
    font-size: 1.3rem !important;
  }
}

.subtext {
  color: $gray-md-lgt-1;
  font-size: 1.1rem;
  padding: 1rem 0;
  text-align: center;
}

.pb-lg {
  padding-bottom: 7rem !important;
}

.circle-rating {
  .bs-rating-star {
    margin: 0.3rem;
  }

  .bs-rating-star.active {
    margin: 0.3rem !important;

    .btn-info {
      color: $white !important;
    }
  }

  .btn {
    min-width: 36px !important;
    min-height: 36px;
    background: #ffffff;
    border: 1px solid #afafaf;
    border-radius: 50% !important;
    padding: 0 !important;
  }

  .btn-info {
    background: $blue-linear;
    color: $white;
  }

  .btn .btn-default {
    color: #444;
  }
}

.text-gray-lgt {
  color: $gray-md-lgt-1;
}

.left-border-list {
  font-size: 20px;
  border-radius: 5px;
  display: block;
  min-width: 5px;
  margin-right: 0.3rem;
  height: 35px;
}

.btm-bordered-select {
  border: 0 !important;
  border-bottom: 1px solid #abbbcf !important;
  border-radius: 0 !important;
  color: $textcolor;
}

.title-main {
  color: $black;
  font-size: 1.2rem;
  font-weight: 700;
}

.modal-md-c {
  max-width: 600px;
}

.btn-secondary-sm {
  border-radius: 30px;
  min-width: auto !important;
  min-height: 30px !important;
  color: $white !important;
  padding: 0 1.3rem !important;
  font-weight: normal;
  font-size: 1rem !important;

  &.btn-secondary:not(:disabled):not(.disabled):active {
    background: $secondary;
  }
}

.btn-ico {
  position: relative;
  top: -1px;
}

// New Rating Start
label.star:before {
  content: "\f005";
  color: $white !important;
  text-shadow: 0px 0px 3px #000000;
  transition: 0.25s;
}

input.star:checked~label.star:before {
  content: "\f005";
  color: #4e3a88 !important;
  text-shadow: none !important;
}

label.star:before {
  content: "\f005" !important;
  font-family: "Font Awesome 5 Free" !important;
}

div.stars {
  display: inline-block;
}

label.star {
  padding: 0.5rem !important;
  font-size: 2rem !important;
}

// // New Rating End
.innertabHeadMain-sm {
  .tabHead {
    color: $textcolor;

    span {
      i {
        color: #9b9b9b;
      }
    }
  }

  .subgroup-back {
    color: #9b9b9b !important;
  }
}

.plain-rounded-card {
  .card {
    box-shadow: none;
  }

  .formInner {
    width: 100%;
  }

  .input-group {
    .form-control {
      background: #ffffff;
      border: 1px solid #ececec;
      border-radius: 6px;
    }
  }

  label {
    font-size: 1rem;
    color: #6e6e6e;
  }
}

.pb-lg {
  padding-bottom: 8rem;
}

.mb-lg {
  margin-bottom: 8rem;
}

.timesheetBtns-c {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.pb-xl {
  padding-bottom: 8rem;
}

//leader
.dashContArea-c {
  padding-left: 60px;
  padding-right: 60px;
}

.title-main {
  h1 {
    color: $black;
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.border-radius-md {
  border-radius: 10px;
}

.boxMain {
  &.card-white {
    padding: 1rem !important;
    height: 100%;
  }

  .boxHead {
    min-height: auto;
    padding-bottom: 1rem;
  }

  &.card-no-space {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.card-title-main {
  border-bottom: 1px dashed #c1bdbd;
  padding: 0.5rem;

  h5 {
    font-size: 1.3rem;
    color: $textcolor;
    font-weight: 600;
    margin-bottom: 0;
    text-align: left;
  }

  .info-circle {
    width: 26px;
    height: 26px;
    background: #f9f9f9;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
  }
}

.info-circle {
  width: 26px;
  height: 26px;
  background: #f9f9f9;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
}

.strengthRow {
  &.list-bordered-btm-dotted {
    border-bottom: 1px dashed #c1bdbd;
    padding: 0.5rem;
    color: $gray-md-lgt-1;
  }

  .list-item {
    color: $gray-md-lgt-1;
  }
}

.legend-btm {
  display: flex;
  align-items: center;
  justify-self: center;
  padding: 1rem 0;

  .chartBtmCol {
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: start;
    align-items: center;
    color: $gray-md;
    font-size: 12px;
    overflow-wrap: break-word;
  }
}

.list-img {
  width: 35px;
  height: 42px;
  padding-bottom: 0.4rem;
  display: flex;
}

.list-img-md {
  width: 42px;
  height: 35px;
}

.btn-row-c {
  .btn-secondary {
    background: $secondary;
    color: $gray-txt;
    font-size: 18px;
  }

  .btn {
    border-radius: 30px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 8px 20px;
    min-width: 100px;
    line-height: normal;
    font-size: 1rem;
    min-height: 30px;

    &.btn-md {
      min-width: 86px;
      min-height: 34px;
      padding: 5px;
    }

    &.btn-small {
      font-size: 16px;
      padding: 5px 24px;
      line-height: 18px;
      height: fit-content;
      border: 0px solid transparent;

      @media only screen and (max-width: 768px) {
        min-width: 75px;
      }
    }

    &.btin-icon {
      padding: 5px 5px 5px 24px;

      @media only screen and (max-width: 768px) {
        padding: 5px 5px 5px 10px;
        min-width: 85px;
      }
    }

    &.btn-primary {
      background: $primary;
      border: 0px solid transparent;

      &:active {
        background: $primary;
      }
    }

    &.btn-secondary {
      background: $secondary;
      color: $white;
    }

    .btn-outline-primary {
      background: $white;
      border-color: var(--primary);
    }

    &.btn-outline-purple {
      background: $white;
      color: $txt-purple;
      border: 1px solid $border-purple;

      &.btn-small {
        font-size: 14px;
        padding: 0.3rem 1rem;
      }

      &.btn-ex-small {
        font-size: 14px;
        padding: 0.3rem 0.2rem;
        min-width: 40px;
      }
    }

    &.btn-outline-danger {
      background: $white;
      color: $outline-danger-text;
      border: 1px solid $outline-danger;
    }
  }
}

.count-lg {
  color: $gray-md-lgt-1;
  font-size: 1.3rem;
}

.txt-sm {
  color: $gray-md-lgt-1;
  font-size: 0.9rem;
}

.txt-green-dark {
  color: #05c858;
}

.gauge-chart__label {
  font-size: 1rem !important;
  color: $gray-md-lgt-1 !important;
  font-weight: normal !important;
}

.btn-row-chart {
  display: flex;

  .chartBtmCol {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: $gray-md;
    margin: 0;
    width: 25%;
    font-size: 12px;

    span {
      border-radius: 10px;
      background: transparent;
    }
  }
}

.black {
  color: $black !important;
}

.divider-rgt {
  border-right: 1px solid #e5e4e4;
}

.delete-acc {
  padding-top: 1.3rem;
}

.link {
  color: #456790 !important;
  font-weight: 600;
}

.rating-label-vertical {
  justify-content: space-between;
  color: $textcolor;
  font-size: 14px;
  position: relative;
}

.lock-scroll {
  overflow-y: hidden;
}

.w-tooltip {
  .tooltip-inner {
    min-width: 180px;
    max-width: 180px;
  }
}

.card-white-md {
  min-height: 240px;
}

.bottom-border-0 {
  border-bottom: 0 !important;
}

.green-linear {
  background: $green-linear !important;
}

.org-linear {
  background: $org-linear !important;
}

.grey-linear {
  background: $grey-linear !important;
}

@media only screen and (min-width: 1200px) {
  .rating-label-vertical {
    text-align: center;
    display: flex;
    min-height: 80px;

    .lft {
      position: absolute;
      left: -20px;
      width: 22%;
    }

    .mid {
      position: absolute;
      left: 178px;
      width: 18%;
    }

    .rgt {
      position: absolute;
      right: -20px;
      width: 22%;
    }
  }
}

.success-checkmark {
  text-align: center;
  line-height: 20px;
  width: 80px;
  height: 80px;
  border: 1px solid $green-1;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $green-1;
  font-size: 2.5rem;
}

.btn-bordered-purple {
  min-width: 80px;
  min-height: 28px;
  background: #ffffff;
  border: 1px solid #b45ed2;
  color: $txt-purple;
  font-size: 14px;
  border-radius: 50px;
}

.positive {
  background: $green-1 !important;
}

.neutral {
  color: $txt-org !important;
}

.card-label-md {
  color: $gray-md-lgt-1;
  font-size: 1.438rem;
}

.legend-lg {
  .chart-legend {
    .legend-labels {
      display: block;
    }
  }
}

.btn-c {
  .btn {
    border-radius: 30px !important;
    background-clip: padding-box;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    outline-offset: -1px;
    padding: 8px 20px;
    min-width: 100px;
    line-height: normal;

    &.btn-primary {
      background: $primary !important;
      border: 0px solid transparent !important;

      &:active {
        background: $primary !important;
      }
    }

    &.btn-secondary {
      background: $secondary;
    }

    &.btn-outline-primary {
      background: $white !important;
      border-color: var(--primary) !important;
      color: $blue;

      &:hover {
        color: $blue;
      }
    }

    &.btn-small {
      font-size: 16px;
      padding: 5px 24px;
      line-height: 18px;
      border: 0px solid transparent;

      @media only screen and (max-width: 768px) {
        min-width: 75px;
      }
    }
  }

  .btn-primary-sm {
    padding: 8px 10px;
    font-size: 0.9rem;
  }
}

.form-control {
  background: #ffffff;
  border: 1px solid #ececec !important;
  border-radius: 6px !important;
  font-size: 1rem;
}

input {
  font-size: 1rem;
}

table.table-c {
  text-align: left;
  font-size: 0.9rem;
  color: $textcolor;
  width: 100%;
  background: $white;
  overflow-wrap: break-word;

  th {
    font-size: 1.1rem;
    background: #f9f9f9;
    text-align: left;
    border: 0;
    border-bottom: 1px solid #e5e4e4;
    padding: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 1300px) {
      font-size: 0.8rem;
      padding: 0.7rem;
    }
  }

  td {
    text-align: left;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;

    @media only screen and (max-width: 1300px) {
      font-size: 0.7rem;
      padding: 0.7rem;
    }
  }
}

.btn-outline-action {
  background: $white !important;
  border: 1px solid #6A46D4 !important;
  color: #6A46D4 !important;
}

.btn-outline-action-active {
  background: $blue-linear !important;
  border: 1px solid #6A46D4 !important;
  color: $white !important;
}

.border-rounded-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.scroll-body {
  height: calc(100vh - 150px);
  overflow-y: auto;

  @media (max-width: 576px) {
    height: calc(100vh - 200px);
  }
}

.lbl-gray {
  color: #adadad;
  font-weight: normal;
}

.border-white {
  border: 1px solid #FFFFFF;
}

.mb-0>a {
  display: block;
  position: relative;
}

.mb-0>a::before:not(.credHeader .mb-0>a::before) {
  content: "\f078";
  /* fa-chevron-down */
  font-family: 'FontAwesome';
  position: absolute;
  right: 0;
}

.mb-0>a[aria-expanded="true"]::before {
  content: "\f077";
  /* fa-chevron-up */
}

.modal-scroll {
  max-height: 420px;
  overflow-y: auto;
  padding-bottom: 30px;
  padding-right: 1rem;
}

.heading-lg {
  font-size: 22px;
}

.link-purple {
  color: $txt-purple !important;
  font-size: 0.875rem;

  &:hover {
    opacity: 0.5rem;
    transition: 0.2 all;
    color: $txt-purple;
    cursor: pointer;
  }
}

.accordion-c {
  details div.summary-open {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: none;
    border: 1px solid #E5E4E4;
    padding: 1rem;
  }

  details div.summary-open>*+* {
    margin-top: 1rem;
    animation: sweep .5s ease-in-out;

  }

  details+details {
    margin-top: 1rem;
  }

  summary {
    list-style: none;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary {
    background: #FFF;
    border-radius: 15px;
    font-size: 1.1rem;
    padding: 0.5rem 3rem;
    cursor: pointer;
    border: 1px solid #E5E4E4;
    position: relative;
    color: $black;
    font-weight: 500;
    box-shadow: 0px 3px 5px #00000012;

    small {
      color: #B7B7B7;
    }
  }

  summary:before {
    position: absolute;
    left: 1rem;
    content: "\2039";
    transform: rotate(270deg);
    height: 1.75rem;
    font-size: 2.5rem;
    color: #A1A1A1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  details[open] summary {
    background: #6544C6;
    color: $white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    small {
      color: $white;
    }
  }

  details[open] summary:before {
    content: "\2039";
    transform: rotate(90deg);
    color: $white;
  }

  details {
    transition: height 1s ease;
  }

  .section-title {
    color: $textcolor;
    font-size: 1.125rem;
  }

  ul {
    margin: 0;
    padding: 0;

    &.list-bordered-btm {
      li {
        list-style-type: none;
        padding: 1rem 0;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &.list-bordered-btm-solid {
      li {
        border-bottom: 1px solid #d5d1d1 !important;

        &:last-child {
          border-bottom: 0 none !important;
        }
      }
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
}

.container-chk input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
label.container-chk:hover input~.checkmark {
  background-color: #eee;
  border: 1px solid #C7C3C3;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
label.container-chk input:checked~.checkmark {
  background-color: $blue-3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
label.container-chk input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
label.container-chk .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

label.container-chk {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $gray-md-lgt-1;
}

.footer-fixed {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 1rem 0;
}

.grp-name {
  label {
    font-size: 0.9rem;
  }

  strong {
    padding-left: 0.5rem;
  }
}

.db-report {
  .main-container {
    padding: 0;

    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.dotted-divider {
  border-right: 1px dashed #AB9D9D;

  @media (max-width: 576px) {
    border-right: 0 none;
  }
}

.weekly-challenge {
  .close {
    color: $black;
  }
}

.toggle-switch {

  #button-13 .knobs:before,
  #button-13 .knobs:after,
  #button-13 .knobs span {
    position: absolute;
    top: 2px;
    width: 20px;
    height: 24px;
    font-size: 0.9rem;
    text-align: center;
    line-height: 1;
    padding: 5px 1px;
    border-radius: 30px;
    transition: 0.3s ease all;
  }

  #button-13 .knobs:before,
  #button-13 .knobs:after {
    color: $white;
    z-index: 1;
  }

  #button-13 .knobs:before {
    content: "Yes";
    left: 5px;
  }

  #button-13 .knobs:after {
    content: "No";
    right: 7px;
    color: $black;
  }

  #button-13 .knobs span {
    width: 24px;
    left: 38px;
    background-color: $white;
    z-index: 2;
  }

  #button-13 .checkbox:checked+.knobs span {
    left: 4px;
    background-color: $white;
  }

  #button-13 .checkbox:checked~.layer {
    background-color: #DADADA;
  }

  .button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
  }

  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    top: 50%;
    width: 66px;
    height: 28px;
    overflow: hidden;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .button.b2 {
    border-radius: 2px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: $blue-3;
    transition: 0.3s ease all;
    z-index: 1;
    border-radius: 30px;
  }
}

.customStepper,
.list-view {
  padding: 1rem;

  .input-group {
    position: relative;

    span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 3;
    }
  }

  a {
    color: #994FE3;
    font-size: 14px;
  }

  p {
    color: #6E6E6E;
    font-size: 13px;
    margin-bottom: 0;
    padding-right: 30px;
  }

  .userGroups {
    border: 1px solid #ECECEC;
    border-radius: 5px;
    max-height: 294px;
    overflow-y: auto;
    padding: 1rem;

    label {
      color: #595959;
      margin-bottom: 1.3rem;
      line-height: normal;
      word-break: break-word;

      p {
        color: #67758E;
      }
    }

    .userStatus {
      .stepperchips {
        width: auto;
        gap: 10px;
        margin-right: 8px;
        background: transparent;
      }
    }

    .userStatusDetails {
      line-height: 19px;

      label {
        margin: 0;

        span {
          font-size: 12px;
        }
      }

      p {
        color: #67758E;
      }
    }

    .grayBg {
      background-color: #F9F9F9;
      border-radius: 50%;
      width: 26px;
      height: 26px;
    }
  }

  .stepperchipsWrapper {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .stepperchips {
    width: 114px;
    height: 35px;
    background: #F9F9F9;
    border-radius: 50px;
    color: #595959;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;

    .icon {
      width: 26px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.purpalBg {
        background: url('assets/img/Info-bg-purpal.svg') no-repeat center center;
      }

      &.blueBg {
        background: url('assets/img/Info-bg-blue.svg') no-repeat center center;
      }
    }

    .info-icon {
      background: #fff;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}


.search-bar {
  &.input-group {
    position: relative;

    span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 3;
      color: #A1A1A1;
    }
  }
}

.circle-ico-purple {
  width: 36px;
  height: 36px;
  background: $secondary;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-body-height {
  min-height: 400px;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file>input[type='file'] {
  display: none
}

.file>label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-view {
  .container-chk {
    padding-left: 0;
    justify-content: space-between;
  }
}

.img-sm {
  img {
    width: 16px;
    height: 16px;
  }
}

.back-arrow {
  i {
    color: #9b9b9b;
  }
}

.user-mngmt {
  .search-bar input {
    min-height: 35px;
  }

  select {
    width: 100%;
  }
}

.stepper-header {
  .steps {
    padding-left: 0;

    small {
      color: #B7B7B7;
    }
  }
}

.stepper-header .steps .step.active .step-bullet {
  background-color: #fff !important;
  border: 6px solid #E21FCF;
}

.stepper-header .steps .step .step-bullet {
  height: 24px !important;
  width: 24px !important;
  background-color: $blue-3;
}

.bs-stepper .line,
.bs-stepper-line {
  margin: 0;
  min-width: 0;
  position: absolute;
  top: 10px !important;
  border-top-width: 2px;
  border-top-style: dashed;
  flex: auto;
  height: 0;
  /* margin: 0 134px; */
  left: 147px;
  min-width: 90%;
  border-top-color: #B5CCEC;

  @media (max-width: 576px) {
    left: 50px;

  }
}

.stepper-header .steps {
  padding: 0;
}

.stepper-header .step .step-bullet {
  background: $blue-3 !important;
  border: 6px solid #EDEBF3;
}

.stepper-header .steps .step .step-title {
  color: #595959;
  font-size: 1rem;
  font-weight: 500;
}

.stepper-header .steps .step.completed .step-bullet {
  background: #4E3A88 !important;
  border: 0 none !important;
}

.stepper-header .steps .step.completed .step-bullet::before {
  position: absolute;
  left: -8px;
  top: 50%;
  height: 20%;
  width: 3px;
  background-color: #ffffff;
  content: "";
  transform: translateX(15px) rotate(-45deg);
  transform-origin: left bottom;
}

.stepper-header .steps .step.completed .step-bullet::after {
  position: absolute;
  left: 0px;
  bottom: 5px;
  height: 3px;
  width: 60%;
  background-color: #ffffff;
  content: "";
  transform: translateX(9px) rotate(-45deg);
  transform-origin: left bottom;
}

.stepper-header .steps .step.completed {

  .bs-stepper .line,
  .bs-stepper-line {
    border-top-color: #B845BA;
  }
}

.stepper-header .step.active .step-title {
  color: $blue-3;
  font-weight: 600;
}

.stepper-body {
  margin: 25px auto 0 !important;
}

.label-sm {
  font-size: 0.9rem;
  color: $textcolor;
  font-weight: normal !important;
  margin-bottom: 0;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
}

.modal {
  .nav-tabs {
    border-bottom: 1px solid #ECECEC !important;
  }
}

// multiselect
.selected-item {
  background: #F2F2F2 !important;
  border: 0 none !important;
  color: $black !important;
  font-size: 0.9rem;
  padding: 0.1rem 1rem !important;
  border-radius: 5px !important;
  margin-bottom: 0 !important;

  a {
    color: #AAA4A4 !important;
    font-size: 1.1rem;
  }
}

.multiselect-dropdown {
  .dropdown-list {
    margin-top: 0 !important;
    box-shadow: none !important;

    .multiselect-item-checkbox input+div:before {
      height: 20px !important;
      width: 20px !important;
      background-color: #fff;
      border: 2px solid #BBBBBB !important;
      border-radius: 4px;
      top: 40% !important;
    }

    .multiselect-item-checkbox input[type=checkbox]:checked+div:before {
      background: #4E3A88 !important;
      border: 1px solid #4E3A88 !important;
    }

    .multiselect-item-checkbox input[type=checkbox]+div {
      font-size: 14px;
    }

    .multiselect-item-checkbox input[type=checkbox]+div:after {
      left: 4px !important;
      width: 10px !important;
      height: 5px !important;
      margin-top: -3px !important;
      top: 44% !important;
    }
  }

  .dropdown-btn {
    padding: 6px 12px 5px !important;
    border: 1px solid #ECECEC !important;
  }

  .dropdown-btn .dropdown-multiselect__caret:before {
    border-width: 6px 6px 0 !important;
  }
}

.required-asterisk {
  color: red;
  font-weight: bold;
  margin-left: 3px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 3;
}

.modal {
  .nav-tabs {
    border-bottom: 1px solid #ECECEC !important;
  }
}

.min-w-100 {
  min-width: 100% !important;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 3;
}

.time {
  color: #595959;
  font-size: 12px;
  line-height: 14px;
}

.options {
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.ft-bold {
  font-weight: 600;
}

.form-check-label {
  display: flex;
  padding-left: 0px;
}

.let-c {
  padding-top: 6px !important;
}

label {
  input {
    display: none;
  }

  .letter {
    padding-top: 7px;
    border: 1px solid #ccc;
    min-width: 35px;
    min-height: 35px;
    overflow: hidden;
    line-height: 1;
    text-align: center;
    border-radius: 100%;
    font-size: 20px;
    color: #6E6E6E;
    cursor: pointer;
  }
}

input {
  &:checked {
    &+.letter {
      background: linear-gradient(124deg, #4E3A88, #6A46D4);
      color: #fff;
    }
  }
}

.required-asterisk {
  color: red;
  font-weight: 700;
  margin-left: 3px;
}

.add-icon-white {
  svg {
    fill: $white;
  }
}

.delete-ico {
  cursor: pointer;
}

.circle-purple-sm {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $secondary;
  border: 1px solid $white;

  img {
    width: 100%;
    height: 100%;
  }
}

.title-md-gray {
  h1 {
    font-size: 1.3rem;
  }
}

.progress-bg {
  background-color: #EBEBEB;
  border-radius: 10px;
  height: 14px;

  &.progress {
    height: 14px;
  }
}

.reward-section {
  h2 {
    color: $txt-dark-purple;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .progress-bar {
    border-radius: 0;
    background: $bg-pink;

    span {
      font-size: 13px;
      position: absolute;
      top: -1rem;
      color: #969696;
    }
  }
}

.weekly-challenges {
  .card-white {
    color: #595959;
    padding-top: 1rem !important;
    padding-bottom: 1.2rem !important;
  }
}

.challenges-legend {
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      display: inline-flex;
      align-items: center;
      padding-left: 4rem;

      span {
        margin-right: 0.5rem;
      }
    }
  }

}

.circle-xs {
  height: 10px;
  width: 10px;
  display: flex;
  border-radius: 50%;
}

.missed {
  background: $missed;
}

.circle-challenge {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: -1px 3px 6px #84848429;

  i {
    font-size: 1.6rem;

    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }

    &::before {
      color: #736776;
    }

    font-size: 1.6rem;
  }

  .icon-Video-icon {
    font-size: 1.2rem;

    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
}

.circle-completed {
  background: $completed;

  i {
    &::before {
      color: $white !important;
    }
  }
}

.circle-missed {
  background: $missed-bg;

  i {
    &::before {
      color: $white !important;
    }
  }
}

.circle-inprogress {
  background: $inprogress;
}

.circle-notstarted {
  background: $white;
}

.circle-shadow {
  box-shadow: -1px 3px 6px #84848429;
}

.content-area {
  z-index: 2;
  position: relative;
  display: flex;
}

.path {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    right: -30px;
    z-index: 2;

    li {
      position: relative;
      list-style-type: none;
      top: -20px;
      cursor: pointer;

      &:last-child {
        top: -8px;
        left: -32px;
      }
    }
  }

  .bg-box {
    &:last-child {
      ul {
        li {
          &:last-child {
            top: -20px;
            left: 0;
          }
        }
      }
    }

    .days-label {
      color: #AEAEAE;
      text-transform: uppercase;
    }
  }
}

.card-gray {
  min-height: 42px;
  background: #FAFAFC;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.path {
  position: relative;
  height: 100%;
  width: 100%;
}

.curve {
  width: 85%;
  margin: 0 auto;
}

.bg-box {
  span {
    position: absolute;
    transform: rotate(-90deg);
  }

  &:first-of-type {
    width: 100%;
    height: 140px;
    position: absolute;
    top: 65px;

    &:before {
      content: "";
      border-radius: 20px;
      position: absolute;
      top: calc(-50% - -15px);
      left: 0;
      height: 120px;
      width: 100%;
      z-index: 0;
      background: #FAFAFC;
    }

    span {
      top: -6px;
      left: 0;
    }

    .curve {
      background-image: url(assets/img/curve.svg);
      background-repeat: no-repeat;
      background-position: 100% 0px;
      height: 140px;
      position: relative;
      z-index: 2;

      &:before {
        content: "";
        background-color: $background-color_1;
        height: 10px;
        width: calc(100% - 110px);
        position: absolute;
        left: 50px;
      }
    }
  }

  &:nth-child(2) {
    width: 100%;
    height: 140px;
    position: absolute;
    top: 194px;

    &:before {
      content: "";
      border-radius: 20px;
      position: absolute;
      top: calc(-50% - -15px);
      left: 0;
      height: 120px;
      width: 100%;
      z-index: 0;
      background: #FAFAFC;
    }

    span {
      top: -8px;
      left: 0;
    }

    .curve {
      background-image: url(assets/img/curve.svg);
      background-repeat: no-repeat;
      background-position: 100% 0px;
      transform: scaleX(-1);
      height: 140px;
      position: relative;
      z-index: 2;

      &:before {
        content: "";
        background-color: $background-color_1;
        height: 10px;
        width: calc(100% - 118px);
        position: absolute;
        right: 55px;
      }

      ul {
        li {
          i {
            transform: scaleX(-1);
          }
        }
      }
    }
  }

  &:nth-child(3) {
    width: 100%;
    height: 140px;
    position: absolute;
    top: 324px;

    &:before {
      content: "";
      border-radius: 20px;
      position: absolute;
      top: calc(-50% - -15px);
      left: 0;
      height: 120px;
      width: 100%;
      z-index: 0;
      background: #FAFAFC;
    }

    span {
      top: -8px;
      left: -12px;
    }

    .curve {
      background-image: url(assets/img/curve.svg);
      background-repeat: no-repeat;
      background-position: 100% 0px;
      height: 140px;
      position: relative;
      z-index: 2;

      &:before {
        content: "";
        background-color: $background-color_1;
        height: 10px;
        width: calc(100% - 118px);
        position: absolute;
        right: 56px;
      }
    }
  }

  &:nth-child(4) {
    width: 100%;
    height: 140px;
    position: absolute;
    top: 453px;

    &:before {
      content: "";
      border-radius: 20px;
      position: absolute;
      top: calc(-50% - -15px);
      left: 0;
      height: 120px;
      width: 100%;
      z-index: 0;
      background: #FAFAFC;
    }

    span {
      top: -8px;
      left: -4px;
    }

    .curve {
      background-image: url(assets/img/curve.svg);
      background-repeat: no-repeat;
      background-position: 100% 0px;
      transform: scaleX(-1);
      height: 140px;
      position: relative;
      z-index: 2;

      &:before {
        content: "";
        background-color: $background-color_1;
        height: 10px;
        width: calc(100% - 118px);
        position: absolute;
        right: 55px;
      }

      ul {
        li {
          i {
            transform: scaleX(-1);
          }
        }
      }
    }
  }

  &:nth-child(5) {
    width: 100%;
    height: 140px;
    position: absolute;
    top: 582px;

    &:before {
      content: "";
      border-radius: 20px;
      position: absolute;
      top: calc(-50% - -15px);
      left: 0;
      height: 120px;
      width: 100%;
      z-index: 0;
      background: #FAFAFC;
    }

    span {
      top: -8px;
      left: 8px;
    }

    .curve {
      background-image: none;

      &:before {
        content: "";
        background-color: $background-color_1;
        height: 10px;
        width: calc(100% - 218px);
        position: absolute;
        right: 86px;
      }
    }
  }

  &:nth-child(6) {
    width: 100%;
    height: 140px;
    position: absolute;
    top: 711px;

    &:before {
      content: "";
      border-radius: 20px;
      position: absolute;
      top: calc(-50% - -15px);
      left: 0;
      height: 120px;
      width: 100%;
      z-index: 0;
      background: #FAFAFC;
    }

    span {
      top: -8px;
      left: -8px;
    }

    .curve {
      background-image: url('assets/img/curve.svg');
      background-repeat: no-repeat;
      background-position: 100% 0px;
      transform: scaleX(-1);
      height: 140px;
      position: relative;
      z-index: 2;

      &:before {
        content: "";
        background-color: $background-color_1;
        height: 10px;
        width: calc(100% - 118px);
        position: absolute;
        right: 55px;
      }

      &:after {
        content: "";
        background-color: $background-color_1;
        height: 10px;
        width: calc(100% - 118px);
        position: absolute;
        right: 55px;
        bottom: 1px;
      }
    }
  }

  &:nth-child(7) {
    width: 100%;
    height: 140px;
    position: absolute;
    top: 841px;

    &:before {
      content: "";
      border-radius: 20px;
      position: absolute;
      top: calc(-50% - -15px);
      left: 0;
      height: 120px;
      width: 100%;
      z-index: 0;
      background: #FAFAFC;
    }

    span {
      top: -8px;
      left: -8px;
    }

    .curve {
      background-image: none;

      &:before {
        content: "";
        background-color: $background-color_1;
        height: 10px;
        width: calc(100% - 218px);
        position: absolute;
        right: 86px;
      }
    }
  }


&:nth-child(5) {
  width: 100%;
  height: 140px;
  position: absolute;
  top: 582px;

  &:before {
    content: "";
    border-radius: 20px;
    position: absolute;
    top: calc(-50% - -15px);
    left: 0;
    height: 120px;
    width: 100%;
    z-index: 0;
    background: #FAFAFC;
  }

  span {
    top: -8px;
    left: 8px;
  }

  .curve {
    background-image: none;

    &:before {
      content: "";
      background-color: $background-color_1;
      height: 10px;
      width: calc(100% - 180px);
      position: absolute;
      right: 58px;
    }
  }
}
}
// This style is used in multiple places
.challenge-badges {
  .mid-img-badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mid-img-badge-sm {
    width: 30px !important;
    height: 30px !important;
  }
  .mid-txt-badge {
    position: absolute;
  }
}
.white {
  color: $white;
}

.header-inner {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.purple-lgt-txt {
  color: $purple-light;
  font-size: 24px;
}

.unsubscribe {
  h4 {
    color: $textcolor;
    font-size: 1.3rem;
  }

  p {
    color: $textcolor;
    font-size: 1rem;
  }

  .logoMain {
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 0;
  }

  .logoInner {
    display: inline-block;
    width: auto;
    list-style: none;
  }

  .logoInner a {
    display: inline-block;
    width: auto;
  }

  .navbar-white {
    display: flex;
    align-items: center;
    min-height: 80px;

    background: $header-bg;
    color: $header-text;
  }

  .loggleBtn a {
    background: transparent;
    height: auto !important;
    border-radius: 0;
    font-size: 24px;
  }

  .loggleBtn a i {
    color: #fff;
  }
}
.right-tabs {
  .nav-tabs {
    border-bottom: 1px solid #E5E4E4 !important;
    padding-left: 1.5rem !important;
  }
  .nav-link {
    padding: 8px 16px !important;
  }
  .track-btn {
    margin-top: 0.5rem;
    display: flex;
  }
}

.profile {
  position: relative;
  width: fit-content;
}

img.mb.user-icon {
  width: 80px;
  height: 80px;
  background: #fff8ef;
}

.p-xlg {
  padding-left: 35px;
  padding-right: 35px;
}

.pinline {
  text-align: center;
  display: block;
  overflow: hidden;
  margin: 20px 0;

  span {
    position: relative;
    display: inline-block;
    line-height: 1;

    &:before {
      left: 100%;
      margin-left: 10px;
    }

    &:after {
      right: 100%;
      margin-right: 10px;
    }
  }
}

.pinline span:before,
.pinline span:after {
  content: "";
  position: absolute;
  top: 50%;
  border: 1px dashed #ccc;
  width: 99999px;
}

.purple-dot {
  width: 150px;
  height: 150px;
  border: 2px solid green;
  background: lightblue;
  position: relative;
  transform: translate(0);

  &:before {
    content: "";
    position: absolute;
    inset: -5px;
    transform: translate(10px, 8px);
    z-index: -1;
    background: conic-gradient(from 90deg at 40% -25%, #ffd700, #f79d03, #ee6907, #e6390a, #de0d0d, #d61039, #cf1261, #c71585, #cf1261, #d61039, #de0d0d, #ee6907, #f79d03, #ffd700, #ffd700, #ffd700);
    filter: blur(10px);
  }
}

.border-btm {
  border-bottom: 2px dotted #E5E4E4;
  width: 90%;
  margin: auto;
}

.card-content {
  border-bottom: 2px dotted #E5E4E4;
}

.middle-content {
  column-gap: 10px;
}

.user-name {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}
.leaderboard {
  .user-name {
    font-size: 13px;
    line-height: 14px;
  }
}

.mid-img-chart {
  position: absolute;
  top: 30%;
  left: 34%;
}


.journey-sequence {
  display: flex;
}

.journey-sm-ico {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 18px;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    .main-container {
      .nested-tab {
          &.tab-purple {
            .nav-tabs {
              border: 1px solid purple !important;
              border-radius: 50px;
              margin-top: 0 !important;
              max-width: 270px;
            }
            .nav-item {
              min-width: 130px;
              &:last-child {
                a {
                  left: 0 !important;
                }
              }
            }
          }
        .nav-tabs {
          margin-top: 0.8rem;
          border-bottom: 0 none;
            
          .nav-link {
            background: $white;
            color: $textcolor !important;
            &.active {
              background: $blue-3;
              color: $white !important;
              border-bottom: 0 none !important;

            }
          }
            .nav-item {
             
              &:last-child a {
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                position: relative;
                left: -12px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
        }
      }
    }
  }
}

.btn-no-bg {
  background: transparent;
  border: 0 none;
}

.trophy-section {
  .inner-img {
    width: 30px;
    height: 30px;
    top: 22px;
  }
}
.bottom-dot {
  background: rgb(77,56,134);
  background: radial-gradient(circle, rgb(95, 72, 158) 77%, rgb(101, 77, 165) 93%, rgba(125,95,206,1) 100%);;
  width: 8px;
  position: absolute;
  height: 8px;
  top: -4px;
  border-radius: 50%;
  left: 35px;
  -moz-box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
  -webkit-box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
  transform: rotate(205deg);
}
.congratulation-card {
  background: $purple-linear-light !important;
  color: $white;
}
.star-bg {
 background: $purple-linear-light;
}
.curved {
  background: $white;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  img {
    width: 80px;
    height: 80px;
    position: relative;
    left: -6px;
  }
}
@media (max-width: 576px) {
  .modal-md-c {
    max-width: 500px;
  }

  .pb-5-sm-c {
    padding-bottom: 8rem !important;
  }

  .position-sm {
    flex-direction: column;
    align-items: flex-start;
  }

  .chartArea {
    min-height: 375px;
  }

  .nav-sm {
    display: flex !important;
  }

  .btn-sm-header {
    padding: 8px 4px !important;
    font-size: 0.8rem !important;
  }

  .boxMain {
    &.card-white {
      padding: 0.5rem !important;
    }
  }

  .col-xs {
    width: 10% !important;
  }

  .circle-challenge {
    width: 35px;
    height: 35px;

    width: 32px;
    height: 32px;

    img {
      width: 60%;
    }

    i {
      font-size: 1.1rem;
    }
  }

  .challenge-section {
    ul {
      right: 0;

      li {
        top: -14px;

        &:last-child {
          top: 14px;
          left: 2px;
        }
      }
    }
  }

  .path {
    ul {
      li {
        top: -10px;

        &:last-child {
          top: 22px;
          left: -24px;
        }
      }
    }
  }

  .challenges-legend {
    li {
      padding-left: 1rem !important;
      font-size: 0.8rem;
    }
  }

  .bg-box {
    .days-label {
      left: -15px !important;
    }

    &:nth-child(3) span {
      left: -27px !important
    }

    &:nth-child(4) span {
      left: -18px !important
    }

    &:nth-child(5) span {
      left: -5px !important
    }

    &:nth-child(odd) span.activity-counter, &:nth-child(even) span.activity-counter {
      left: 0 !important;
    }
  }
  .column-reverse-sm {
    flex-direction: column-reverse;
  }
  .ico-mob {
    background: $white;
    border: 1px solid #DBDBDB;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    padding: 0.3rem;
  }
  .card-white {
    h5 {
      text-align: left;
    }
  }
  .bg-box:nth-child(5) {
    .curve {
      &::before {
        width: calc(100% - 130px);
        right: 26px;
      }
    }
  }
  .path {
    .bg-box {
      &:last-child {
        ul {
          li {
            &:last-child {
              top: -10px;
            }
          }
        }
      }
    }
  }
  .card-body {
    padding: 1rem;
  }
  .calendarMain {
    .legend {
      display: block !important;
    }
  }
  

}

@media (max-width: 768px) {
  .modal-dialog-centered {
    align-items: flex-end;
  }

  .embed-responsive {
    height: 420px !important;
  }

  .modal-content {
    bottom: 1rem;
  }

  .px-lg {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .custom-cal {
    width: 100%;
  }

  .navbar-nav {
    .nameCircle {
      display: none !important;
    }
  }

  .tabHead {
    font-weight: normal;
  }

  .text-center-sm {
    text-align: center;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .d-blcok-sm {
    display: flex !important;
  }

  .close {
    color: $white;
    text-shadow: none;
    opacity: 1;
    font-size: 2rem !important;
  }

  .d-block-sm {
    display: block !important;
  }

  .innertabHeadMain-sm {
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 1035 !important;

    .tabHead {
      span {
        i {
          color: $white;
        }
      }
    }
  }

  .wrapper {
    .footer-inner-action {
      padding-bottom: 2.2rem;
    }
  }

  .txt-md {
    font-size: 1.5rem !important;
  }

  .icon-md {
    font-size: 2.5rem;
  }

  .closeMenuMain {
    padding: 2.3rem 1rem 0rem 1rem;
  }

  .mb-nav-none {
    display: none !important;
  }

  .dashContArea-c {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pb-5-md-c {
    padding-bottom: 8rem !important;
  }

  .legend {
    display: block;
  }
  .calendarMain {
    .legend {
      display: flex;
    }
  }

  .count-box {
    font-size: 1.2rem !important;
  }

  .legend-lg {
    .chart-legend {
      .legend-labels {
        padding: 0 2rem;
      }
    }
  }

  .donut-wrapper {
    .pie-label {
      font-size: 12px;
    }
  }

  .card-title-main h5 {
    font-size: 1rem;
    padding-right: 0.4rem;
  }

  select {
    background: $white;
    color: $textcolor;
  }

  .modal-title {
    font-size: 1rem;
  }

  .overviewMain {
    margin: 0 0 5px;
  }

  .curve {
    width: 75%;
    margin: 0 auto;
  }

  .tab-sm-none {
    ul.nav-tabs:not(.tab-show ul.nav-tabs) {
      display: none;
    }
  }

  .challenge-info-mob {
    background: #785bc6;
    position: relative;
    color: $white;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .modal{
    padding: 10px;
  }
  .modal-header .close {
    display: none;
  }
  .c-form-modal {
    select {
      min-width: 120px;
    }
    .form-check-input {
      margin-left: -10px;
    }
  }
  
}

@media (max-width: 991px) {
  .wrapper {
    .main-header {
      min-height: 110px !important;
      padding-top: 1.5rem;
      position: fixed;
      width: 100%;
    }

    .innertabHeadMain {
      min-height: 110px;
      padding-top: 1.2rem !important;
    }
  }

  .content-center-sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .track-list-name {
    width: 100%;
  }

  .credHeader {
    min-height: 110px !important;
    padding-top: 1.5rem;
  }

  .pb-5-md-c {
    padding-bottom: 8rem !important;
  }

  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
  }

  #no-more-tables td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  #no-more-tables td:before {
    position: absolute;
    top: 12px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  #no-more-tables td:before {
    content: attr(data-title);
  }

  .pl-0-c {
    padding-left: 0 !important;
  }

  .bg-box:first-of-type {
    position: relative;
    top: 65px;
  }

  .bg-box:nth-child(2) {
    position: relative;
    top: 54px;
  }

  .bg-box:nth-child(3) {
    position: relative;
    top: 44px;
  }

  .bg-box:nth-child(4) {
    position: relative;
    top: 33px;
  }

  .bg-box:nth-child(5) {
    position: relative;
    top: 22px;
  }

  .bg-box:nth-child(6) {
    position: relative;
    top: 11px;
  }

  .bg-box:nth-child(7) {
    position: relative;
    top: 1px;
  }
}

@media (max-width: 1024px) {
  label.star {
    padding: 0.4rem !important;
    font-size: 1.8rem !important;
  }
}

@media (max-width: 1199.2px) {
  .rating-label-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5rem;

    .lft {
      /* left: -30px; */
      width: 100%;
      position: relative;
    }

    .mid {
      position: relative;
      top: -32px;
    }

    .rgt {
      /* position: absolute; */
      right: 0;
      top: 0;
      position: relative;
    }
  }

  .circle-rating {
    span[role="slider"] {
      display: flex;
      flex-direction: column;
    }

    .bs-rating-star {
      font-size: 20px !important;
    }
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1200px) {
  label.star {
    font-size: 1.4rem !important;
  }

  .card-white h5 {
    font-size: 1rem;
  }
}

/**New CSS End
*************************************/

// rewards
.main-content {
  background-color: #F4F3F9;
  padding: 55px 45px;
}

.card-gap {
  width: 98%;
  gap: 2%;
}

.card-rewards {
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
}

.left-title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}

.subheading {
  color: grey;
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
}

select#Leaderboard {
  padding: 5px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  color: gray;
  width: 100%;
  border: 1px solid #ECECEC;
  position: relative;
  /* appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; */
}

/* select#Leaderboard:after{
  content: "";
  background-image: url('./Assets/down-chevron.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
} */
select#Leaderboard:focus {
  outline: none;
}

.left-user {
  order: -1;
}

.user-name {
  color: #3a3a3a;
  font-size: 14px;
  line-height: 15px;
}

.user-points {
  color: #E51083;
  font-size: 13px;
  font-weight: 600;
}

.user-icon {
  width: 55px;
  height: 55px;
}

.grey-card {
  border-radius: 30px;
  background: #FAFAFA;
  padding: 10px;
  img {
    width: 45px;
    height: 45px;
  }
  .statsRight {
    position: relative;
    display: flex;
    flex-direction: column;
    &:after {
      content: "";
      width: 0; 
      height: 0;
      position: relative;
      top: 43%;
      right: 0;
    }
  }
  &.up {
    .statsRight {
      &:after {
        border-left: $triangle-size solid transparent;
        border-right: $triangle-size solid transparent;
        border-bottom: $triangle-size solid #3BCAF7;
      }
    }
  }
  &.down {
    .statsRight {
      &:after {
        border-left: $triangle-size solid transparent;
        border-right: $triangle-size solid transparent;
        border-top: $triangle-size solid #EA7171;
      }
    }
  }
}
.top3item {
  .user-name {
    span {
    position: relative;
    &:after {
      content: "";
      width: 0; 
      height: 0;
      position: absolute;
      top: 32%;
      right: -17px;
    }
  }
  }
  &.up {
    .user-name {
      span{
      &:after {
        border-left: $triangle-size solid transparent;
        border-right: $triangle-size solid transparent;
        border-bottom: $triangle-size solid #3BCAF7;
      }
    }
    }
  }
  &.down {
    .user-name {
      span{
      &:after {
        border-left: $triangle-size solid transparent;
        border-right: $triangle-size solid transparent;
        border-top: $triangle-size solid #EA7171;
      }
    }
    }
  }
}

// .bs-calendar-container {

// }

.usertitle {
  font-size: 15px;
  color: #3A3A3A;
  font-weight: 600;
}

.week-action {
  font-size: 12px;
  line-height: 15px;
}

.view-all {
  color: #994FE3;
  font-size: 14px;
  line-height: 18px;
}

.select-wrp {
  width: 95%;
  margin: auto;
}

.grey-card span {
  font-weight: 500;
}

.user-ranking {
  position: relative;
}

.user-ranking span {
  position: absolute;
  right: 0;
  background: #fff;
  border: 1px solid #D2D2D2;
  border-radius: 50%;
  padding: 0px 5px;
  font-size: 12px;
  box-shadow: 0px 4px 8px #00000029;
  font-weight: 600;
  width: 20px;
  height: 20px;
}

.height-auto {
  height: fit-content;
}

.user-count {
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  padding-bottom: 3px;
  font-weight: 600;
}

.user-list .grey-card.self {
  background-color: #EBE3FF;
}

.ranking-arrow {
  padding-left: 1px;
}

.user-leaderboard {
  margin: auto;
}

/* Right part css */
.right-cards {
  padding: 14px;
  border-radius: 12px;
  background-color: #fff;
}

.skill-badges image {
  width: 59px;
  height: 53px;
  object-fit: contain;
}

.skill-heading {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}

.badge-light-content {
  color: #595959;
  font-size: 14px;
  line-height: 16px;
}

.level-badge {
  background-color: #3A2D55;
  border-radius: 5px;
  width: 35px;
}

.level-badge span {
  color: #fff;
  font-size: 11px;
  line-height: 14px;
  display: block;
  text-align: center;
  padding: 1px;
}

.w-25 {
  width: 25%;
}

.next-up {
  font-size: 12px;
  line-height: 13px;
  background-color: #FFEC6C;
  color: #000;
  width: 61px;
  border-radius: 3px;
  font-weight: 600;
}

.year {
  font-weight: 600;
}

.date-input {
  color: #595959;
  font-size: 12px;
  line-height: 17px;
  border: 1px solid #ECECEC;
  border-radius: 5px;
}

.date-picker {
  border: 1px solid #ECECEC;
  background: #fff !important;
}

.fa-calendar:before {
  color: #929292;
  opacity: 0.8;
}

.metrics-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 14px 16px;
  overflow-x: hidden;
}

.date-input:focus-visible {
  outline: none;
}

.week {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}

.emo-res {
  color: #595959;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.box {
  border: 1px solid #E3E3E3;
  border-radius: 12px;
  padding: 9px 5px;
}

.score {
  font-size: 16px;
  line-height: 14px;
}

.bold-score {
  font-weight: bold;
}

.outof {
  color: #929292;
}

.vertical-line {
  padding-right: 20px;
  border-right: 1px solid #E5E4E4;
}

.vertical-line.week3line {
  border-right: 1.6px solid #7900FF;
  position: relative;
}

.week-challenge .vertical-line:first-child {
  padding-left: 0px;
}

.week-challenge .vertical-line:last-child {
  border-right: none;
  padding-right: 0px;
}

.week3-box {
  background-color: #F4F3F9;
}

.journey-heading {
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
}

hr.horizantal-line {
  border-radius: 6px;
  border: 2px solid #C6C6C6;
  position: absolute;
  width: 100%;
  top: 44%;
}

.journey-wrapper {
  position: relative;
}

.journey-text {
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
}

.journey-div {
  width: fit-content;
}

.jour-icons,
.centered-icons {
  width: 34px;
}

.journey-last {
  position: relative;
  top: -15px;
}

.circle-bg {
  background: #fff;
  border-radius: 50%;
  padding: 6px 6px;
  filter: drop-shadow(0px 3px 6px #00000029);
}

img.centered-icons {
  width: 24px;
  height: 18px;
  object-fit: contain;
}

.diff-color {
  color: #B25C68 !important;
}

.slider-arrows img {
  border: 1px solid #DBDBDB;
  border-radius: 50%;
  padding: 3px 6px;
}

.slider-arrows {
  width: 11%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: fit-content;
}

.flex-end {
  margin-left: auto;
}

.weekly-metrics {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  .challenge-box {
      width: 100%;
      padding: 20px 10px 0;
      margin-bottom: 20px;
      position: relative;
      border: none;
      @media screen and (min-width: 420px) {
        width: 49%;
        &:first-child {
          border-right: 1px solid #ececec
        }
        &:nth-child(3) {
          border-right: 1px solid #ececec
        }
      }
      @media screen and (min-width: 760px) {

      }
      @media screen and (min-width: 1120px) {
        &:nth-child(2) {
          border-right: 1px solid #ececec
        }
        width: 24%;
      }
      .box {
          flex-grow: 1;
      }
      .flag {
          display: none;
      }
      &.incomplete {
        padding-top: 0;
      .box {
        background-color:#F4F3F9;
      }
      .flag {
          display: flex;
          align-items: flex-start;
          margin-left: -10px;
          &:before {
            content: "";
            height: 100%;
            width: 1px;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #7900ff
        }
      }
    }
  }
}

.create-assessment-tabs a#tab1-link {
  left: 8px !important;
}



/* Mobile css */
@media only screen and (max-width:991px) {
  .date-pic {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sm-100 {
    width: 100%;
  }

  .hori-border {
    width: 100%;
    margin: 0px 6px;
    border-width: 2px;
    border-style: none;
    border-top-style: dotted;
    border-color: #67758E;
  }

  img.desktop-hide.mb-flag {
    position: relative;
    top: -3px;
    left: -1px;
  }

  span.emo-res.flag-border {
    border-right: 1px solid #7900FF;
    padding-right: 8px;
  }

  .no-border {
    border-bottom: none !important;
  }

  .datepicker {
    padding: 3px 0px;
  }

  .week-challenge .challenge-box:last-child {
    display: none;
  }

  .years {
    font-size: 12px;
    line-height: 18px;
  }

  select#Leaderboard {
    width: 90%;
    margin: auto;
    display: block;
    padding: 8px;
  }

  .select-wrp {
    width: 100%;
  }

  .user-list {
    padding: 14px;
  }

  .second-block {
    position: relative;
    left: 5px;
  }

  span.diamond img {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 0%;
    top: 43%;
  }

  .small-icons {
    padding-right: 5px;
    display: flex;
    top: 16px;
    position: relative;
  }

  .jour-wt {
    z-index: 99;
  }

  .small-icons .circle-bg img {
    width: 20px;
    height: 15px;
  }

  .circle-bg {
    padding: 4px;
    width: fit-content;
    margin-right: 5px;
  }

  .mbjourney-outer img {
    width: 35px;
    height: 35px;
    object-fit: cover;
  }

  .card {
    border-radius: 12px;
  }

  .mb-down {
    margin-top: 80px;
  }

  .mbjourney-outer {
    position: relative;
  }

  .mb-hr {
    border: 2px solid #C6C6C6;
    border-radius: 5px;
    position: absolute;
    width: 99%;
    top: 52%;
  }

  .mb-journey {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
  }

  .journey-card {
    border-radius: 20px;
    background: #fff;
    padding: 3px;
    box-shadow: 0px -7px 10px #4D3886;
    position: absolute;
    top: 108%;
    width: 96%;
  }

  .progress-count {
    position: relative;
  }

  .first-card-gap {
    padding: 0px !important;
  }

  svg.rank-arrow {
    width: 16px;
  }

  .wel-border .centered-icons {
    width: 38px;
    height: 34px;
    object-fit: contain;
  }

  .wel-crown {
    width: 18px;
    height: 25px;
    object-fit: contain;
  }

  .card-wd.first {
    background-color: #2A1D4B;
  }

  .card-wd {
    background-color: #4B387D;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
  }

  .card-wd:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  .card-wd:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .rank {
    color: #fff;
    font-size: 16px;
    line-height: 14px;
  }

  .card-gap {
    width: 100%;
    padding: 0px 18px;
  }

  .gap {
    gap: 2px;
  }

  .welcome-section {
    padding-bottom: 75px;
    padding-top: 20px;
    background-image: linear-gradient(#4D3886, #7D5FCE);
    position: relative;
  }

  .mb-pd {
    padding-left: 14px;
    padding-right: 14px;
  }

  .small-hide {
    display: none;
  }

  .left-title {
    border-bottom: 1px solid #E5E4E4;
    margin-bottom: 16px;
    padding: 0px 14px;
  }

  .main-content {
    background-color: #F4F3F9;
    padding: 0px;
  }

  .box {
    width: 50%;
    padding: 9px 7px;
  }

  .emo-res br {
    display: none;
  }


  .badges-earned {
    border-bottom: 1px solid #E5E4E4;
  }

  .mobile-tabs {
    font-size: 14px;
    line-height: 16px;
    color: #595959;
    margin-right: 33px;
  }

  .mb-badge-border {
    border-bottom: 1px solid #E5E4E4;
  }

  img.mb-badgeicon {
    width: 60px;
  }

  .mb-badgeicon.last-part {
    width: 28px;
  }

  .icon-wrapper {
    width: fit-content;
    background-image: linear-gradient(#79D2FF, #6E8FFF);
    border-radius: 5px;
    padding: 8px;
  }

  .icon-check {
    background-image: linear-gradient(#D285D5, #B56CFE);
  }

  .icon-builder {
    background-image: linear-gradient(#FDAB80, #F674A0);
  }

  .mb-content {
    font-size: 14px;
    line-height: 16px;
    color: #595959;
  }

  .mobile-tabs.active {
    color: #4E3A88 !important;
    font-weight: 600;
    border-bottom: 2px solid;
  }

  .w-33 {
    width: 33.33%;
  }

  .w-20 {
    width: 20%;
  }

  .w-80 {
    width: 80%;
  }

  .activity-heading {
    color: #595959;
    font-size: 17px;
    line-height: 26px;
  }

  .activity-status {
    font-size: 14px;
    line-height: 16px;
    color: #67758E;
  }

  .count {
    color: #67758E;
    font-size: 22px;
    line-height: 26px;
  }

  .mb-right-cards {
    padding: 14px 0px;
    border-radius: 20px;
    background-color: #fff;
  }

  .mb-card {
    padding: 0px 14px;
  }

  .mb-right-cards .skill-heading {
    padding: 0px 14px;
  }

  .metrics-card {
    padding: 14px 0px;
  }

  .metrics-card .skill-heading {
    padding: 0px 14px;
  }

}



@media screen and (max-width:992px) {
  .small-hide {
    display: none;
  }
}

@media screen and (min-width:993px) {
  .desktop-hide {
    display: none;
  }
}

@media screen and (min-width:763px) and (max-width:992px) {
  .box {
    width: 100%;
  }
}

@media screen and (max-width:992px) {
  .skill-heading {
    border-bottom: 1px solid #E5E4E4;
    margin-bottom: 15px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .modal:has(.external){
    top: 0 !important;
  }
}

@media screen and (min-width:1024px) and (max-width:1199px) {
  .user-points {
    font-size: 12px;
  }

  .grey-card img {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
  }

  .user-leaderboard {
    margin-left: 0px;
    margin-right: 0px;
    width: 100% !important;
  }

  .main-content {
    padding: 55px 20px;
  }

  .datepicker {
    flex-wrap: nowrap;
  }

  .slider-arrows {
    width: auto;
  }

  .slider-arrows.small-hide img:first-child {
    margin-right: 8px;
  }

}

@media screen and (min-width:1200px) and (max-width:1300px) {
  .main-content {
    padding: 55px 25px;
  }
 }

//settings
.bck-clr {
  background-color: #F4F3F9;
  padding: 35px 0px;
}

.small-card {
  flex-basis: 48%;
  background-color: #fff;
  color: #000;
  border-radius: 12px;
}

.card-inner span {
  color: #595959;
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
}

.card-inner {
  object-fit: contain;
}

.sr-text {
  position: absolute;
  visibility: hidden;
  top: - 10000px;
  left: -10000px;
}

.title-main .green {
  color: #30B442;
}

.good-text {
  color: $green-1;
}

.bad-text {
  color: $orange;
}

.ok-text {
  color: $purple-1;
}

