@use "sass:math";

/* Screen Size Limiters */

@mixin slt($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}

@mixin sgt($size) {
  @media only screen and (min-width: $size) {
    @content;
  }
}

/* Trangle Generator */

@mixin triangle($size, $color, $direction) {
  $opposite: (top:bottom, right:left, left:right, bottom:top);
  border: ($size * .5) solid transparent;
  border-#{map-get($opposite, $direction)}-width: ($size * .75);
  border-#{map-get($opposite, $direction)}-color: $color;
  border-#{$direction}-width: 0;
}

/* Tooltip Generator */

@mixin tooltip($direction) {
  // Common variables
  $background-color: #8963f5;
  $triangle-size: 10px;
  $z-index: 1000;

  // Directional mapping
  $opposite: (bottom:top, left:right, right:left, top:bottom);
  $side: (bottom:left, left:top, right:top, top:left);
  $translate: (bottom: X, left: Y, right: Y, top: X);

  &::before,
  &::after {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: all 250ms ease-in-out 150ms;
    user-select: none;
    z-index: $z-index;
  }

  &:hover::before,
  &:hover::after {
    opacity: 100%;
  }

  &::before {
    content: "";
    @include triangle(
      $triangle-size,
      $background-color,
      map-get($opposite, $direction)
    );

    // Positioning
    #{map-get($side, $direction)}: calc(50% - (#{$triangle-size} * .5));
    #{map-get($opposite, $direction)}: 100%;
  }

  &::after {
    background: $background-color;
    border-radius: 2px;
    box-shadow: 0 1em 2em -0.5em rgba(194, 87, 87, 0.144);
    color: #fff;
    content: attr(aria-label);
    font-size: 14px;
    line-height: 1;
    padding: 0.5em 0.6em;
    text-align: center;
    text-transform: none;

    // Let the content set the size of the tooltips
    // but this will also keep them from being obnoxious
    max-width: 21em;
    min-width: 3em;

    // overflow ellipsis if wider than max-width
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // Positioning
    #{map-get($side, $direction)}: 50%;
    // Slight overlap with triangle to prevent rounding space
    #{map-get($opposite, $direction)}: calc(100% + (#{$triangle-size} * .7));
    transform: translate#{map-get($translate, $direction)}(-50%);
  }

  // Don't show empty tooltips
  &[aria-label=""]::before,
  &[aria-label=""]::after {
    display: none;
  }
}

/* Backwards Compatible SCSS */

@mixin align-items($arg) {
  $args: (center:center, flex-end:end, flex-start:start);
  -webkit-box-align: map-get($args, $arg);
  -ms-flex-align: map-get($args, $arg);
  align-items: $arg;
}

@mixin column-gap($gap) {
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex($max-width) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 $max-width;
  flex: 0 0 $max-width;
  max-width: $max-width;
}

@mixin flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-wrap($arg) {
  -ms-flex-wrap: $arg;
  flex-wrap: $arg;
}

@mixin justify-content($arg) {
  $args: (center:center, flex-end:end, flex-start:start, space-between:justify);
  -webkit-box-pack: map-get($args, $arg);
  -ms-flex-pack: map-get($args, $arg);
  justify-content: $arg;
}

@mixin width-fit-content {
  width: -moz-fit-content;
  width: fit-content;
}
