@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?o5sgdx');
  src: url('fonts/icomoon.eot?o5sgdx#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?o5sgdx') format('truetype'),
    url('fonts/icomoon.woff?o5sgdx') format('woff'),
    url('fonts/icomoon.svg?o5sgdx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Abstract-Thinking:before {
  content: "\e900";
  color: #921db4;
}

.icon-Achieve-Goal:before {
  content: "\e901";
  color: #921db4;
}

.icon-Assessment-icon:before {
  content: "\e902";
  color: #736776;
}

.icon-Build-Positive-Brain:before {
  content: "\e903";
  color: #921db4;
}

.icon-Did-Felt-Journal:before {
  content: "\e904";
  color: #921db4;
}

.icon-Go-Adventure:before {
  content: "\e905";
  color: #921db4;
}

.icon-Inventory-icon:before {
  content: "\e906";
  color: #736776;
}

.icon-Make-Stress-Plan:before {
  content: "\e907";
  color: #921db4;
}

.icon-Manage-Message:before {
  content: "\e908";
  color: #921db4;
}

.icon-Practice-Decision-Making:before {
  content: "\e909";
  color: #921db4;
}

.icon-Read-icon:before {
  content: "\e90a";
  color: #736776;
}

.icon-Reflection-icon:before {
  content: "\e90b";
  color: #736776;
}

.icon-Video-icon:before {
  content: "\e90c";
  color: #736776;
}

.icon-What-You-Do:before {
  content: "\e90d";
  color: #921db4;
}